<!-- eslint-disable max-len -->
<template>
  <div class="c-popup-slide c-popup-slide--right" :id="id">
    <div class="c-popup-slide__backdrop c-btn-popup-slide-close" :data-popup-id="id"></div>
    <div class="c-popup-slide__container px-0">
      <!-- @start Header-->
      <div class="c-popup-slide__header">
        <div class="c-popup-slide__content">
          <h3>Agregar paquete</h3>
        </div>
        <div class="close c-popup-slide__close">
          <img class="c-btn-popup-slide-close" src="~@/assets/img/icon/icon-closex.svg" :data-popup-id="id" />
        </div>
      </div>
      <!-- @end Header-->
      <!-- @start content-->
      <div class="c-popup-slide__body">
        <div class="c-popup-slide__body__container">
          <div class="c-popup-slide__content c-scrollbar">
            <div class="content-information-the-package">
              <!-- @start location delivery-->
              <div class="content-package-delivery">
                <h4>1) Indicar Punto Sharf</h4>
                <input-district id="scharffpoint-step-3-type-2-modal-pop-district" text="Buscar distrito" @getDistrictId="setDestinationDistrict2" serviceType="2" @input="districtId = 0"></input-district>
                <div class="c-select input-field curso-pointer">
                  <select id="scharff-point" v-model="puntoScharffId" @change="setMarket()">
                    <option v-for="item in lstScharffPoint" v-bind:value="item" v-bind:key="item.ScharffPointId">
                      {{ item.Name }}
                    </option>
                  </select>
                  <label for="" class="select-label">Punto Sharf</label>
                </div>
                <div class="c-input input-field mb-2">
                  <textarea v-model="destinationAddress.additionalAddress" maxlength="299" id="txtareaReference" data-length="120" class="materialize-textarea" placeholder="Escriba aquí si desea agregar alguna indicación especial para su pedido"></textarea>
                  <label for="txtareaReference" class="">Información adicional</label>
                </div>
                <div class="c-popup-slide__map" id="mp_ps_type2"></div>
              </div>
              <div class="content-package-delivery">
                <h4>2) Datos de quien recibe</h4>
                <article v-if="DestinationContact === null" id="btnModalContactFavorite" class="c-grid--services__item pt-0 pb-0">
                  <a @click="openContactModal('destination')"
                    ><div id="" class="card c-card c-card--service-box d-flex p-1">
                      <div class="card-image">
                        <div class="card-title color-text-black"><span class="card-sub-title color-text-black">Escoge tu</span><span class="card-option">contacto</span></div>
                      </div>
                      <img src="@/assets/img/service-point-sharff-3.svg" class="translatex" /></div
                  ></a>
                </article>
                <div v-if="DestinationContact !== null" id="c-contact-send-2" class="c-btn-contact-click c-content--spacing-block">
                  <div class="collection c-collection c-collection--not-hover c-collection--card">
                    <div class="collection-item collection-item--arrow-next collection-item--icon">
                      <a @click="editDestinationContact()" class="collection-item--icon collection-item--icon--user"
                        ><div class="c-text-medium font-weight-bold">
                          {{ DestinationContact.name }}
                        </div>
                        <div class="c-text-medium">
                          {{ DestinationContact.phone }}
                        </div></a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-package-tab-result">
                <h4>3) Tamaño del paquete</h4>
                <article class="c-grid--services__item">
                  <div class="c-section--content-package">
                    <ul id="navList" class="navList c-nav--tab-d-c d-flex justify-content-between m-0 w-100 tabs h-100 h-100 c-bg-none">
                      <li v-for="item in listPackageSize" v-bind:key="item.id" class="c-tab-button item-package tab h-100 line-height-initial">
                        <a
                          :href="'#' + item.id"
                          v-on:click="setTypePackageSize(item)"
                          v-bind:class="{
                            active: item.id === packageSize.type,
                            'active-package': item.id === packageSize.type
                          }"
                          class="item-package-proof w-100 color-black"
                        >
                          <img v-if="item.id === 'Pequeno' || item.id === 'Pequeño'" src="~@/assets/img/icon/icon-small.svg" alt="" />
                          <img v-if="item.id === 'Mediano'" src="~@/assets/img/icon/icon-medium.svg" alt="" />
                          <img v-if="item.id === 'Grande'" src="~@/assets/img/icon/icon-grand.svg" alt="" />
                          <img v-if="item.id === 'Personalizado'" src="~@/assets/img/icon/icon-small.svg" alt="" />
                          <p>{{ item.description }}</p></a
                        >
                      </li>
                      <li class="indicator" style="left: 0px; right: 419px"></li>
                    </ul>
                  </div>
                </article>
              </div>
              <div class="content-package-information-result">
                <div v-for="item in listPackageSize" v-bind:key="item.id" :id="item.id" class="c-content--box-shadow mb-1" v-show="item.id === packageSize.type">
                  <article class="c-grid--services__item c-section--dateContent">
                    <div class="c-content--information-package">
                      <h3>{{ item.title }}</h3>
                      <!-- <img
                        v-if="item.id === 'Pequeno' || item.id === 'Pequeño'"
                        src="~@/assets/img/icon/icon-small.svg"
                        alt=""
                        width="63px"
                        height="56px"
                      />
                      <img
                        v-if="item.id === 'Mediano'"
                        src="~@/assets/img/icon/icon-medium.svg"
                        alt=""
                        width="63px"
                        height="56px"
                      />
                      <img
                        v-if="item.id === 'Grande'"
                        src="~@/assets/img/icon/icon-grand.svg"
                        alt=""
                        width="63px"
                        height="56px"
                      />
                      <img
                        v-if="item.id === 'Personalizado'"
                        src="~@/assets/img/icon/icon-other.svg"
                        alt=""
                        width="63px"
                        height="56px"
                      /> -->
                    </div>
                  </article>
                  <article class="c-grid--services__item c-section--dateContent">
                    <div v-if="item.id !== 'Personalizado'" class="c-section--content-package-information">
                      <div class="item-package-information">
                        Alto:
                        <p>{{ item.packageSize.height }}cm</p>
                      </div>
                      <div class="item-package-information">
                        Ancho:
                        <p>{{ item.packageSize.width }}cm</p>
                      </div>
                      <div class="item-package-information">
                        Largo:
                        <p>{{ item.packageSize.lengths }}cm</p>
                      </div>
                    </div>
                    <div v-else>
                      <div class="row m-0">
                        <div class="col s4">
                          <div class="c-input input-field">
                            <input type="text" maxlength="4" v-model="packageSize.height" @keypress="isNumber($event)" />
                            <label for="" class="active">Alto</label>
                          </div>
                        </div>
                        <div class="col s4">
                          <div class="c-input input-field">
                            <input type="text" maxlength="4" v-bind:value="packageSize.width" @keypress="isNumber($event)" v-on:input="packageSize.width = $event.target.value" />
                            <label for="" class="active">Ancho</label>
                          </div>
                        </div>
                        <div class="col s4">
                          <div class="c-input input-field">
                            <input type="text" maxlength="4" v-model="packageSize.lengths" @keypress="isNumber($event)" />
                            <label for="" class="active">Largo</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  <article class="c-grid--services__item c-section--dateContent">
                    <div class="c-content--range d-flex justify-content-between">
                      <!-- <form action="#" class="w-80">
                        <label for="">Peso (kg)</label>
                        <p class="range-field">
                          <input
                            v-model="packageSize.weight"
                            type="range"
                            id="test5"
                            min="0"
                            max="130"
                            class="m-0"
                          /><span class="thumb"
                            ><span class="value"></span
                          ></span>
                        </p>
                      </form> -->
                      <div class="c-modal--directions-body content w-40">
                        <label for="">Peso (kg)</label>
                        <div class="c-input input-field">
                          <input type="number" min="1" max="100" v-model="packageSize.weight" />
                          <label for class="font-weight-light active">kg</label>
                        </div>
                      </div>
                    </div>
                  </article>
                  <article class="c-grid--services__item c-section--dateContent">
                    <div class="c-modal--directions-body">
                      <div class="c-input input-field">
                        <input type="text" v-model="description" />
                        <label for="" class="active">Descripción del producto a enviar</label>
                      </div>
                    </div>
                    <div class="c-modal--directions-body">
                      <div class="c-input input-field">
                        <input type="text" v-model="productValue" @keypress="isNumber($event)" />
                        <label for="" class="active">Valor del producto (S/)</label>
                      </div>
                    </div>
                  </article>
                </div>

                <article class="c-grid--services__item c-section--dateContent">
                  <div class="c-section--incognito mt-2" data-position="top" data-tooltip="Recuerda que el seguro de este servicio cubre hasta S/700. Te recomendamos enviar productos que tengan hasta ese valor como máximo.">
                    <img src="~@/assets/img/icon/icon-exclamation-black.svg" alt="" class="mr-1 tooltipped" data-position="bottom" data-tooltip="I am a tooltip" />Tenemos garantía de hasta
                    <span class="font-weight-bold">S/700 soles</span>
                  </div>
                  <div class="c-section--incognito icongnito-warning" v-if="amountMax"><img src="~@/assets/img/icon/icon-exclamation-red.svg" alt="" class="mr-1" />Haz excedido el monto permitido, de continuar aceptas nuestras restricciones</div>
                </article>
                <article class="c-grid--services__item c-section--dateContent" v-if="false">
                  <div class="m-0 d-flex justify-content-center">
                    <a id="btnOpenModalRestriction" class="c-button c-button--five c-button--mobile-small waves-effect restriccion"> Ver restricciones<img src="~@/assets/img/icon/icon-danger.svg" alt="" /></a>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-popup-slide__footer">
        <div class="c-nav c-nav--floating-footer-sticky__spaceButtonPair">
          <a :data-popup-id="id" @click="saveDestinationAddress1()" class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect boton-guardar btn-guarda1">Guardar</a>
          <button :data-popup-id="id" class="c-button c-button--width-fuid c-button--mobile-small waves-effect c-button--flat c-btn-popup-slide-close">Salir</button>
        </div>
      </div>
    </div>
    <button id="setSchaffPointMarker" v-on:click="setScharffPointMarkedSelect()" style="display: none"></button>
    <input id="scharffPointMarkerId" style="display: none" />
  </div>
</template>

<script>
import M from "materialize-css";
import InputDistrict from "../General/InputDistrict.vue";
import Map from "../../commons/mixins/maps";
import { getScharffPointFare } from "../../services/fare";

export default {
  components: {
    InputDistrict
  },
  name: "ModalScharffPointType2Package",
  props: {
    id: String
  },
  data() {
    return {
      map: null,
      scharffPointVaule: "",
      lstScharffPoint: [],
      isDestinationFocused: false,
      listPackageSize: [
        {
          id: "Pequeno",
          title: "Paquete Pequeño",
          description: "PEQUEÑO",
          imgUrl: "~@/assets/img/icon/icon-small.svg",
          packageSize: {
            height: 10, // Altura
            lengths: 12, // Largo
            width: 25, // Ancho
            maxWeight: 20 // Peso
          }
        },
        {
          id: "Mediano",
          title: "Paquete Mediano",
          description: "MEDIANO",
          imgUrl: "~@/assets/img/icon/icon-medium.svg",
          packageSize: {
            height: 20, // Altura
            lengths: 23, // Largo
            width: 38, // Ancho
            maxWeight: 60
          }
        },
        {
          id: "Grande",
          title: "Paquete Grande",
          description: "GRANDE",
          imgUrl: "~@/assets/img/icon/icon-grand.svg",
          packageSize: {
            height: 35, // Altura
            lengths: 50, // Largo
            width: 52, // Ancho
            maxWeight: 130
          }
        }
      ],
      packageSize: {
        height: 10, // Altura
        lengths: 12, // Largo
        width: 25, // Ancho
        type: "Pequeno",
        weight: 0,
        maxWeight: 20,
      },
      defaultPackageSize: {
        height: 10, // Altura
        lengths: 12, // Largo
        width: 25, // Ancho
        type: "Pequeno",
        weight: 0,
        maxWeight: 20,
      },
      contact: null,
      description: null,
      productValue: 0,
      destinationAddress: {
        description: "",
        lat: null,
        lng: null,
        favorite: false,
        additionalAddress: ""
      },
      districtId: 0,
      puntoScharffId: null,
      options: {
        data: {}
      }
    };
  },
  methods: {
    setMarket() {
      this.map.centerMap({
        lat: this.puntoScharffId.Location.Lat,
        lng: this.puntoScharffId.Location.Lng
      });
    },
    async setScharffPointMarkedSelect() {
      const id = document.getElementById("scharffPointMarkerId").value;
      let scharffPoint = null;
      this.lstScharffPoint.forEach((item) => {
        if (item.ScharffPointId.toString() === id) {
          scharffPoint = item;
        }
      });
      this.puntoScharffId = scharffPoint;
      document.getElementById("scharff-point").value = scharffPoint;
      this.initSelects();
    },
    async getScharffPointLst() {
      this.$store
        .dispatch("GET_SCHARFF_POINT", this.districtId.id)
        .then((response) => {
          this.lstScharffPoint = response;
        })
        .then(() => {
          this.lstScharffPoint.forEach((item) => {
            this.map?.addMarkerScharffPoint(item.Location, item.Name, item.Name, item);
          });
          this.initSelects();
        });
    },
    isNumber(evnt) {
      const evt = evnt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
        return false;
      }
      return true;
    },
    validateAddPackage() {
      if (this.packageSize.weight === null || this.packageSize.weight === 0 || this.packageSize.weight === "0") {
        this.showError("Debe de ingresar el peso del paquete");
        return false;
      }
      if (this.packageSize.weight > this.packageSize.maxWeight) {
        this.showError(`El peso máximo para el paquete ${this.packageSize.type} es de ${this.packageSize.maxWeight / 10} kg`);
        return false;
      }
      if (this.description === null) {
        this.showError("Debe de ingresar la descripcion");
        return false;
      }
      if (this.productValue === null || this.productValue === 0) {
        this.showError("Debe de ingresar el valor del producto");
        return false;
      }
      if (this.districtId === 0) {
        this.showError("Debe seleccionar el distrito de las opciones ");
        return false;
      }
      if (this.puntoScharffId === 0) {
        this.showError("Debe de ingresar el punto sharf");
        return false;
      }
      if (this.DestinationContact === null) {
        this.showError("Debe de ingresar los datos del contacto");
        return false;
      }
      return true;
    },
    setModel(origin, destination) {
      const fareRequest = {
        PackageType: destination.packageSize.type === "Pequeno" ? "Pequeño" : destination.packageSize.type,
        PackageValue: destination.productValue,
        AcceptInsurance: false, // No se usa Seguro
        PackageWidth: destination.packageSize.width,
        PackageHeight: destination.packageSize.height,
        PackageWeight: destination.packageSize.weight,
        PackageDeep: destination.packageSize.lengths,
        OriginDistrictId: origin.districtId.id,
        DestinationDistrictId: destination.destinationAddress.districtId.id,
        IsUsingSubscription: false
      };
      return fareRequest;
    },
    saveDestinationAddress1() {
      if (this.validateAddPackage()) {
        const destinationTemp = {
          index: this.$store.getters.SCHARFFPOINT_INDEX_DESTINATION,
          item: {
            description: this.description,
            destinationAddress: {
              additionalAddress: this.destinationAddress.additionalAddress,
              lat: this.destinationAddress.lat,
              lng: this.destinationAddress.lng,
              districtId: this.districtId,
              puntoScharffId: this.puntoScharffId,
              contact: this.DestinationContact
            },
            productValue: this.productValue,
            packageSize: this.packageSize,
            fare: 0,
            discountFare: null
          }
        };

        const obj = this.setModel(this.origin, destinationTemp.item);
        const responseFare = getScharffPointFare(obj)
          .then((response) => {
            if (response.status == 200) {
              destinationTemp.item.fare = response.data.totalFare;
              const destination = JSON.parse(JSON.stringify(destinationTemp));
              this.$store.dispatch("SCHARFFPOINT_ORDER_SET_DESTINATION", destination);
              this.clearData();
            }
          })
          .catch((error) => {
            this.$swal({
              title: "Error!",
              text: error,
              icon: "error",
              confirmButtonText: "Aceptar"
            });
          });
        this.$store.dispatch("HELPER_SET_ADD_DESTINATION_CONTACT", null);

        const btnPopupSlideClose = document.querySelectorAll(".btn-guarda1");
        const popupId = btnPopupSlideClose[0].getAttribute("data-popup-id");
        const popup = document.getElementById(popupId);
        popup.classList.remove("is-active");
      }
    },
    setTypePackageSize(item) {
      if (item.id !== "other") {
        this.packageSize.height = item.packageSize.height;
        this.packageSize.lengths = item.packageSize.lengths;
        this.packageSize.width = item.packageSize.width;
        this.packageSize.type = item.id;
        this.packageSize.maxWeight = item.packageSize.maxWeight;
        this.packageSize.weight = item.packageSize.maxWeight;
      } else {
        this.packageSize.type = "other";
      }
    },
    AddAddressFavorite(type) {
      if (type === "origin") {
        this.$eventBus.$emit("updateAddressInput", this.destinationAddress.description);
        this.$store.dispatch("HELPER_SET_ADD_ADDRESS", this.destinationAddress);
        this.destinationAddress.favorite = true;
      }
      const modalAddAddress = document.querySelector("#modal-add-address");
      const instance = M.Modal.init(modalAddAddress, { dismissible: false });
      instance.open();
    },
    editDestinationContact() {
      this.openContactModal("destination");
    },
    openContactModal(type) {
      this.showLoading();
      this.$store
        .dispatch("GET_CONTACTS")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const contacts = success.data.result.list;
            this.$store.dispatch("GENERAL_SET_CONTACTS", contacts);
            this.$store.dispatch("HELPER_SET_MODAL_CONTACT_TYPE", type);
            const modalContactFavorite = document.querySelector("#modal-contact");
            const instance = M.Modal.init(modalContactFavorite, {
              dismissible: false
            });
            instance.open();
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
    },
    async setDestinationDistrict2(id) {
      this.districtId = id;
      await this.getScharffPointLst();
    },

    getDestinationAddressData(addressData, placeResult) {
      this.destinationAddress.description = placeResult.formatted_address;
      this.destinationAddress.lat = addressData.latitude;
      this.destinationAddress.lng = addressData.longitude;
      this.isDestinationFocused = false;
      // this.saveDestinationAddress();
      this.openContactModal("destination");
    },
    getDestinationSelectedAddress(favoriteLocation) {
      this.destinationAddress.description = favoriteLocation.address;
      this.destinationAddress.lat = favoriteLocation.location.Lat;
      this.destinationAddress.lng = favoriteLocation.location.Lng;
      this.$refs.destinationAddress.update(favoriteLocation.address);
      this.isDestinationFocused = false;
      // this.saveDestinationAddress();
      this.openContactModal("destination");
    },
    async clearData() {
      this.packageSize = { ...this.defaultPackageSize };
      this.description = null;
      this.productValue = null;
      this.districtId = 0;
      this.puntoScharffId = null;
      this.$refs.districtId = null;
      this.destinationAddress.additionalAddress = "";
      this.lstScharffPoint = [];
      this.isDestinationFocused = false;

      this.map.clearMarkersScharffPoint();

      this.$store.dispatch("HELPER_SET_ADD_DESTINATION_CONTACT", null);
      this.initSelects();
    },
    setData(destination) {
      this.packageSize.height = destination.packageSize.height;
      this.packageSize.lengths = destination.packageSize.lengths;
      this.packageSize.width = destination.packageSize.width;
      this.packageSize.type = destination.packageSize.type === "Pequeño" ? "Pequeno" : destination.packageSize.type;
      this.packageSize.weight = destination.packageSize.weight;
      this.description = destination.description;
      this.productValue = destination.productValue;
      this.districtId = destination.destinationAddress.districtId;
      this.destinationAddress.description = destination.destinationAddress.description;
      this.destinationAddress.lat = destination.destinationAddress.lat;
      this.destinationAddress.lng = destination.destinationAddress.lng;
      this.puntoScharffId = destination.destinationAddress.puntoScharffId;
      this.getScharffPointLst();
      this.isDestinationFocused = false;
      this.$store.dispatch(
        "HELPER_SET_ADD_DESTINATION_CONTACT",
        destination.destinationAddress.contact
      );
      this.destinationAddress.additionalAddress = destination.destinationAddress.additionalAddress;
      this.initSelects();
    }
  },
  mounted() {
    document.addEventListener("DOMContentLoaded", () => {
      const elems = document.querySelectorAll(".tooltipped");
      M.Tooltip.init(elems);
    });
    this.map = new Map();
    this.map.initMap("mp_ps_type2");
    this.$eventBus.$on("scharffPointClearDataDestinationType2Modal", () => {
      this.clearData();
    });
    this.$eventBus.$on("scharffPointDataDestinationType2Modal", (destination) => {
      this.setData(destination);
    });
  },
  computed: {
    DestinationContact() {
      return this.$store.getters.GENERAL_DESTINATION_CONTACT;
    },
    origin() {
      return this.$store.getters.SCHARFFPOINT_ORDER_ORIGIN;
    },
    amountMax() {
      return this.productValue > 1000;
    }
  }
};
</script>

<style></style>
