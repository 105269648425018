<!-- eslint-disable max-len -->
<template>
  <div id="modalOnboardingScharffPointApp" class="c-modal modal">
    <div class="c-section--content-title-before">
      <h2 class="text-center">Punto Sharf</h2>
    </div>

    <div class="c-modal--img text-center">
      <slider-onboarding-scharff-point />
    </div>

    <div class="c-nav c-content--button-skip">
      <button
        class="c-button c-button--primary c-button--width-fuid c-button--flat c-button--mobile-small waves-effect c-modal--button modal-close"
      >
        Omitir
      </button>
    </div>
  </div>
</template>

<script>
import SliderOnboardingScharffPoint from "../PuntoScharff/SliderOnboardingScharffPoint.vue";

export default {
  components: { SliderOnboardingScharffPoint },
  name: "ModalScharffPointSlider",
};
</script>

<style></style>
