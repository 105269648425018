<template>
  <div class="c-popup-slide c-popup-slide--right" :id="id">
    <div class="c-popup-slide__backdrop c-btn-popup-slide-close" :data-popup-id="id"></div>
    <div class="c-popup-slide__container add_package_slide px-0">
      <!-- @start Header-->
      <div class="header_add_packagev2">
        <div class="c-btn-popup-slide-close back_button" :data-popup-id="id">
          <svg width="32" height="32" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000">
            <path d="m15 6-6 6 6 6" stroke="#000" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="header_title">Agregar paquete</div>
      </div>

      <!-- @start content-->
      <div class="body_add_packagev2">
        <div class="sections_container">

          <!-- Tipo de envío -->
          <div class="title_section-and-section">
            <FormSectionTitle title="Tipo de envío" />
            <div style="margin: -12px -12px 0 -12px;">
              <CustomRadioButton
                radioId="opcion1"
                :inputValue="typesOfShipments.pointToHome"
                v-model="shipmentType"
                label="Punto Sharf a domicilio"
              />
              <CustomRadioButton
                radioId="opcion2"
                :inputValue="typesOfShipments.pointToPoint"
                v-model="shipmentType"
                label="Punto Sharf a Punto Sharf"
              />
            </div>
          </div>

          <!-- Método de pago -->
          <div class="title_section-and-section">
            <FormSectionTitle title="Método de pago" />
            <div style="margin: -12px -12px 0 -12px;">
              <CustomRadioButton
                radioId="opcion3"
                :inputValue="false"
                v-model="isShippingPaidAtDestination"
                label="Pago en línea"
              />
              <CustomRadioButton
                radioId="opcion4"
                :inputValue="true"
                v-model="isShippingPaidAtDestination"
                label="Pago al recoger el paquete"
                :disabled="shipmentType === typesOfShipments.pointToHome"
                :helperText="shipmentType === typesOfShipments.pointToHome ? 'Disponible en Punto Sharf a Punto Sharf' : 'Este pago corresponde únicamente al servicio de envío'"
              />
            </div>
          </div>

          <!-- Lugar de entrega -->
          <div class="title_section-and-section">
            <FormSectionTitle title="Lugar de entrega" />
            <div class="delivery-place-inputs">
              <CustomSearchSelect
                v-if='!isShippingPaidAtDestination'
                @setSelectedDisctrict="setDestinationDistrictData"
                ref="CustomSelectComponentRef"
                v-model="destinationDistrictId"
                :repoSearch="getScharffDistrics"
                label="Distrito"
                placeholder="Busca por departamento, provincia o distrito"
                errorMessage="Ingresa un departamento, provincia o distrito y selecciona uno de los resultados sugeridos."
                :showEmptyInputError="tryToAddPackage && !destinationDistrictId"
              />
              <ErrorMessage v-if="!hasLimaOrCallaoDistrict" style="margin-top: 3px" message="Por el momento solo realizamos envíos de Lima Metropolitana a provincia y de provincia a Lima Metropolitana. Intenta ingresar nuevamente un departamento, provincia o distrito y selecciona uno de los resultados sugeridos."/>

              <!-- Point to point -->
              <CustomSelect
                isForScharffPoints
                v-if="shipmentType === typesOfShipments.pointToPoint"
                label="Punto Sharf"
                placeholder="Selecciona un Punto Sharf"
                :optionList="scharffPointsList"
                v-model="destinationPuntoScharff"
                errorMessage="Selecciona un Punto Sharf."
                :showEmptyInputError="tryToAddPackage && !destinationPuntoScharff"
                @change="setMarketV2"
              />
              <div v-show="shipmentType === typesOfShipments.pointToPoint" class="c-popup-slide__map" id="mp_ps_type1"></div>

              <!-- Point to home -->
              <InputText
                v-if="shipmentType === typesOfShipments.pointToHome"
                label="Dirección"
                :maxLength="150"
                placeholder="Calle/avenida, número, departamento, etc."
                v-model="address"
                missingErrorMessage="Ingresa una dirección."
                :showEmptyInputError="tryToAddPackage && !address"
              />
              <InputText
                isOptional
                v-if="shipmentType === typesOfShipments.pointToHome"
                label="Referencia (opcional)"
                :maxLength="150"
                placeholder="Manzana, lote, alrededores"
                v-model="reference"
                missingErrorMessage="Ingresa una referencia."
              />
            </div>
          </div>

          <!-- Datos de quien recibe -->
          <div class="title_section-and-section">
            <FormSectionTitle title="Datos de quien recibe" />
            <div class="delivery-place-inputs">
              <DestinationContactButton
                @click="openContactModal('destination'), trackEvent('click_puntos_envia_paso_3_contacto_btn', `${shipmentType == typesOfShipments.pointToHome ? 'dropoff': 'puntoapunto'}`)"
                :destinationContact="DestinationContact"
                missingErrorMessage="Escoge un contacto. 2"
              />
              <div v-if="tryToAddPackage && !DestinationContact" style="margin-top: -15px;">
                <ErrorMessage
                  message="Escoge un contacto."
                />
              </div>
            </div>
          </div>

          <!-- Características del paquete -->
          <div class="title_section-and-section">
            <FormSectionTitle title="Características del paquete" />
            <div class="delivery-place-inputs">
              <InputText
                label="Descripción del producto"
                :maxLength="150"
                placeholder="¿Qué es lo que envías?"
                v-model="productDescription"
                missingErrorMessage="Ingresa la descripción del producto."
                :showEmptyInputError="tryToAddPackage && !productDescription"
              />
              <CustomCheckbox
                checkboxId="fragile-product"
                v-model="isAFragileProduct"
                label="Producto frágil"
              />
              <div class="package_measurements">
                <div class="attributes weigth">
                  <InputPackageAttribute
                    label="Peso"
                    measure="kg"
                    v-model="packageWeight"
                    missingErrorMessage="Ingresa el Peso."
                    :showEmptyInputError="tryToAddPackage && !packageWeight"
                  />
                </div>
                <ErrorMessage v-show="exceedsWeightLimit" message="El peso máximo permitido por paquete en el servicio Puntos Sharf es 15 kg." />
                <div style="padding-top: 16px;" class="attributes dimencions">
                  <InputPackageAttribute
                    label="Largo"
                    measure="cm"
                    v-model="packageWidth"
                    missingErrorMessage="Ingresa el Largo."
                    :max="maxLength"
                    :showEmptyInputError="tryToAddPackage && !packageWidth"
                  />
                  <InputPackageAttribute
                    label="Alto"
                    measure="cm"
                    v-model="packageHeight"
                    missingErrorMessage="Ingresa el Alto."
                    :max="maxLength"
                    :showEmptyInputError="tryToAddPackage && !packageHeight"
                  />
                  <InputPackageAttribute
                    label="Ancho"
                    measure="cm"
                    v-model="packageDeep"
                    missingErrorMessage="Ingresa el Ancho."
                    :max="maxLength"
                    :showEmptyInputError="tryToAddPackage && !packageDeep"
                  />
                </div>
                <ErrorMessage
                  v-if="
                    packageWidth <= 120 &&
                    packageHeight <= 120 &&
                    packageDeep <= 120
                  "
                  :message="volumetricWeigthMsg"
                  v-show="exceedsvolumetricWeight"
                />
                <div class="attributes weigth" style="padding-top: 16px">
                  <InputPackageAttribute
                    label="Valor del producto"
                    measure="S/"
                    v-model="packageValue"
                    missingErrorMessage="Ingresa el valor del producto."
                    :showEmptyInputError="tryToAddPackage && !packageValue"
                    isCurrency
                  />
                </div>
              </div>
              <div style="padding-top: 8px">
                <InformativeMessage message="Tenemos garantía de hasta S/ 700." />
              </div>
            </div>
          </div>
          <!-- <div v-if="allowSecondAttempt && shipmentType === typesOfShipments.pointToHome">
            <div style="display: flex; flex-direction: column; gap: 4px;">
              <div class="text-l-bold">En caso el destinatario no pueda recibir el paquete</div>
              <div class="text-s-regular" style="color: #737373">Escoge una opción</div>
            </div>
            <div class="secondAttemptOption_container">
              <SecondAttempCards
                :optionSelected="secondAttemptInPS"
                :isLoading="shippingCostIsLoading"
                :aditionalCostCalculated="aditionalCostCalculated"
                @onUpdateOption="updateSecondAttemptOption"
              />
            </div>
            <div class="text-s-regular">El cobro adicional se realizaría solo en caso de que hubiese un 2do intento de entrega en domicilio, luego de haber coordinado contigo.</div>
          </div> -->

          <!-- Información adicional (opcional) -->
          <!-- <div class="title_section-and-section">
            <FormSectionTitle title="Información adicional (opcional)" />
            <InputText
              isTextArea
              placeholder="¿Deseas agregar alguna indicación para tu envío?"
              v-model="adicionalInformation"
            />
          </div> -->

        </div>

        <div class="body_footer">
          <div>
            <ButtonDefinitive
              :isDisabled="shippingCostIsLoading"
              label="Guardar paquete"
              @click="handleAddPackage"
            />
          </div>
          <ShippingCost :isLoading="shippingCostIsLoading" :amount="shippingCost" />
        </div>
      </div>

      <div v-show="false" class="c-popup-slide__body">
        <div class="c-popup-slide__body__container">
          <div class="c-popup-slide__content c-scrollbar">
            <div class="content-information-the-package">
              <!-- @start location delivery-->
              <div class="content-package-delivery">
                <h4>1) Seleccionar el destino/modalidad</h4>
                <div class="c-switch switch">
                  <label class="row">
                    <div class="col s2"><input value="false" type="checkbox" v-model="isNormal" v-on:change="validateNormal()" /><span class="lever"></span></div>
                    <div class="col s10">
                      <div class="c-switch__text">Envio a domicilio</div>
                    </div>
                  </label>
                </div>
                <div class="c-switch switch">
                  <label class="row">
                    <div class="col s2"><input value="true" type="checkbox" v-model="isPointPoint" v-on:change="validateMarkers()" /><span class="lever"></span></div>
                    <div class="col s10">
                      <div class="c-switch__text">
                        Envío de Punto a Punto
                        <a
                          href="#"
                          class="tooltip-bottom"
                          data-tooltip="¡Ahora podrás enviar/recoger tus paquetes desde un Punto Sharf a otro Punto Sharf!"
                          ><img
                            src="../../assets/img/icon/icon-help.svg"
                            width="15"
                            style="
                              position: absolute;
                              right: -21px;
                              top: 2px;
                            " />
                          <img
                            src="../../assets/img/icon/nuevo.png"
                            width="40"
                            style="
                              position: absolute;
                              right: -100px;
                              top: -10px;
                            "
                        /></a>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div class="content-package-delivery" style="margin-top: 20px">
                <h4>2) Indicar lugar de entrega</h4>



                <input-district id="scharffPoint-step-3-modal-pop" text="Buscar distrito" @getDistrictId="setDestinationDistrict" @input="districtId = 0" serviceType="2" @focus="isDestinationFocused = false"> </input-district>



                <div class="c-select input-field curso-pointer" v-show="isPointPoint">
                  <select id="scharff-point" v-model="puntoScharffId" @change="setMarket()">
                    <option v-for="item in lstScharffPoint" v-bind:value="item" v-bind:key="item.ScharffPointId">
                      {{ item.Name }}
                    </option>
                  </select>
                  <label for="" class="select-label">Punto Sharf</label>
                </div>
                <div class="c-input input-field curso-pointer" v-show="!isPointPoint">
                  <!-- <vue-google-autocomplete
                    ref="destinationAddress"
                    id="c-focus-open-modal-search-direction-destination"
                    class="curso-pointer"
                    placeholder
                    v-on:placechanged="getDestinationAddressData"
                    country="pe"
                    v-on:focus="isDestinationFocused = true"
                  ></vue-google-autocomplete> -->
                  <input ref="destinationAddress" type="text" v-model="destinationAddress.description" @focus="isDestinationFocused = true" max="150" maxlength="150" />

                  <label for>Dirección</label>
                  <div id="c-icon-favorite-1" class="c-input--icon btnOpenModalDirecitionClass" v-on:click="AddAddressFavorite('destination', null)">
                    <img v-if="destinationAddress.favorite === true" src="~@/assets/img/icon/icon-estrella-on.svg" alt="favorite" loading="lazy" class="btn-open-modal-direction" />
                    <img v-else src="~@/assets/img/icon/icon-estrella-set.svg" alt="favorite" loading="lazy" class="btn-open-modal-direction" />
                  </div>
                </div>
                <div v-show="!isPointPoint" class="c-input input-field mb-2">
                  <input
                    type="text"
                    v-model="destinationAddress.additionalAddress"
                    placeholder="Acá puedes colocar todo el detalle de la dirección, incluyendo MZ o Lote"
                    @focus="isDestinationFocused = false"
                    max="299"
                    maxlength="299"
                  />
                  <label for class="active">Referencia</label>
                </div>
                <favorite-address id="favoritePackageAddress" v-show="isDestinationFocused === true" @getSelectedAddress="getDestinationSelectedAddress" modalId="modal-contact-destination" />
                <!-- <div v-show="isPointPoint" class="c-popup-slide__map" id="mp_ps_type1"></div> -->
              </div>
              <div class="content-package-delivery">
                <h4>3) Datos de quien recibe</h4>
                <article v-if="DestinationContact === null" id="btnModalContactFavorite" class="c-grid--services__item pt-0 pb-0">
                  <a @click="openContactModal('destination')">
                    <div id="" class="card c-card c-card--service-box d-flex p-1">
                      <div class="card-image">
                        <div class="card-title color-text-black"><span class="card-sub-title color-text-black">Escoge tu</span><span class="card-option">contacto</span></div>
                      </div>
                      <img src="@/assets/img/service-point-sharff-3.svg" class="translatex" />
                    </div>
                  </a>
                </article>
                <div v-if="DestinationContact !== null" id="c-contact-send-2" class="c-btn-contact-click c-content--spacing-block">
                  <div class="collection c-collection c-collection--not-hover c-collection--card">
                    <div class="collection-item collection-item--arrow-next collection-item--icon">
                      <a @click="editDestinationContact()" class="collection-item--icon collection-item--icon--user">
                        <div class="c-text-medium font-weight-bold">
                          {{ DestinationContact.name }}
                        </div>
                        <div class="c-text-medium">
                          {{ DestinationContact.phone }}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-package-tab-result">
                <h4>4) Tamaño del paquete</h4>
                <article class="c-grid--services__item">
                  <div class="c-section--content-package">
                    <ul id="navList" class="navList c-nav--tab-d-c d-flex justify-content-between m-0 w-100 tabs h-100 h-100 c-bg-none">
                      <li v-for="item in listPackageSize" v-bind:key="item.id" class="c-tab-button item-package tab h-100 line-height-initial">
                        <a
                          :href="'#' + item.id"
                          v-on:click="setTypePackageSize(item)"
                          v-bind:class="{
                            active: item.id === packageSize.type,
                            'active-package': item.id === packageSize.type
                          }"
                          class="item-package-proof w-100 color-black"
                        >
                          <img v-if="item.id === 'Pequeno' || item.id === 'Pequeño'" src="~@/assets/img/icon/icon-small.svg" alt="" />
                          <img v-if="item.id === 'Mediano'" src="~@/assets/img/icon/icon-medium.svg" alt="" />
                          <img v-if="item.id === 'Grande'" src="~@/assets/img/icon/icon-grand.svg" alt="" />
                          <img v-if="item.id === 'Personalizado'" src="~@/assets/img/icon/icon-small.svg" alt="" />
                          <p>{{ item.description }}</p>
                        </a>
                      </li>
                      <li class="indicator" style="left: 0px; right: 419px"></li>
                    </ul>
                  </div>
                </article>
              </div>
              <div class="content-package-information-result">
                <div
                  v-for="item in listPackageSize"
                  v-bind:key="item.id"
                  :id="item.id"
                  class="c-content--box-shadow mb-1"
                  v-show="item.id === packageSize.type"
                >
                  <template v-if="packageSize.type == item.id">
                    <article class="c-grid--services__item c-section--dateContent">
                      <div class="c-content--information-package">
                        <h3>{{ item.title }}</h3>
                        <!-- <img v-if="item.id === 'Pequeno' || item.id === 'Pequeño'" src="~@/assets/img/icon/icon-small.svg"
                        alt="" width="63px" height="56px" />
                      <img v-if="item.id === 'Mediano'" src="~@/assets/img/icon/icon-medium.svg" alt="" width="63px"
                        height="56px" />
                      <img v-if="item.id === 'Grande'" src="~@/assets/img/icon/icon-grand.svg" alt="" width="63px"
                        height="56px" />
                      <img v-if="item.id === 'Personalizado'" src="~@/assets/img/icon/icon-other.svg" alt=""
                        width="63px" height="56px" /> -->
                      </div>
                    </article>
                    <article class="c-grid--services__item c-section--dateContent">
                      <div v-if="item.id !== 'Personalizado'" class="c-section--content-package-information">
                        <div class="item-package-information">
                          Alto:
                          <p>{{ item.packageSize.height }}cm</p>
                        </div>
                        <div class="item-package-information">
                          Ancho:
                          <p>{{ item.packageSize.width }}cm</p>
                        </div>
                        <div class="item-package-information">
                          Largo:
                          <p>{{ item.packageSize.lengths }}cm</p>
                        </div>
                      </div>
                      <div v-else>
                        <div class="row m-0">
                          <div class="col s4">
                            <div class="c-input input-field">
                              <input type="text" v-model="packageSize.height" @keypress="isNumber($event)" maxlength="4" />
                              <label for="" class="active">Alto</label>
                            </div>
                          </div>
                          <div class="col s4">
                            <div class="c-input input-field">
                              <input type="text" v-bind:value="packageSize.width" @keypress="isNumber($event)" maxlength="4" v-on:input="packageSize.width = $event.target.value" />
                              <label for="" class="active">Ancho</label>
                            </div>
                          </div>
                          <div class="col s4">
                            <div class="c-input input-field">
                              <input type="text" v-model="packageSize.lengths" maxlength="4" @keypress="isNumber($event)" />
                              <label for="" class="active">Largo</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article class="c-grid--services__item c-section--dateContent">
                      <div class="c-content--range d-flex justify-content-between">
                        <div class="c-modal--directions-body content w-40">
                          <label for="">Peso (kg)</label>
                          <div class="c-input input-field">
                            <input type="number" :min="minWight" :max="maxWeight" v-model="packageSize.weight" />
                            <label for class="font-weight-light active">kg</label>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article class="c-grid--services__item c-section--dateContent">
                      <div class="c-modal--directions-body">
                        <div class="c-input input-field">
                          <input type="text" v-model="description" />
                          <label for="" class="active">Descripción del producto a enviar</label>
                        </div>
                      </div>
                      <div class="c-modal--directions-body">
                        <div class="c-input input-field">
                          <input
                            type="number"
                            v-model="productValue"
                            @keypress="isNumber($event)"
                          />
                          <label for="" class="active"
                            >Valor del producto (S/)</label
                          >
                        </div>
                      </div>
                    </article>
                  </template>
                </div>
                <article class="c-grid--services__item c-section--dateContent">
                  <div class="c-section--incognito mt-2" data-position="top" data-tooltip="Recuerda que el seguro de este servicio cubre hasta S/700. Te recomendamos enviar productos que tengan hasta ese valor como máximo.">
                    <img src="~@/assets/img/icon/icon-exclamation-black.svg" alt="" class="mr-1" />Tenemos garantía de hasta
                    <span class="font-weight-bold">S/700 soles</span>
                  </div>
                  <div class="c-section--incognito icongnito-warning" v-if="amountMax"><img src="~@/assets/img/icon/icon-exclamation-red.svg" alt="" class="mr-1" />Haz excedido el monto permitido, de continuar aceptas nuestras restricciones</div>
                </article>
                <article class="c-grid--services__item c-section--dateContent" v-if="false">
                  <div class="m-0 d-flex justify-content-center">
                    <a id="btnOpenModalRestriction" class="c-button c-button--five c-button--mobile-small waves-effect restriccion"> Ver restricciones<img src="~@/assets/img/icon/icon-danger.svg" alt="" /></a>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="false" class="c-popup-slide__footer">
        <div class="c-nav c-nav--floating-footer-sticky__spaceButtonPair">
          <a :data-popup-id="id" @click="saveDestinationAddress()" class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect boton-guardar">Guardarasdasd</a>
          <button :data-popup-id="id" class="c-button c-button--width-fuid c-button--mobile-small waves-effect c-button--flat c-btn-popup-slide-close">Salir</button>
        </div>
      </div>
    </div>
    <button id="setSchaffPointMarker2" v-on:click="setScharffPointMarkedSelectD()" style="display: none"></button>
    <input id="scharffPointMarkerId2" style="display: none" />
  </div>
</template>

<script>
import M from "materialize-css";
import InputDistrict from "../General/InputDistrict.vue";
import FavoriteAddress from "../General/FavoriteAddress.vue";
import Map from "../../commons/mixins/maps";
import utils from "../../commons/mixins/utils";

import CustomRadioButton from "../Form/CustomRadioButton.vue";
import FormSectionTitle from "../Form/FormSectionTitle.vue";
import CustomSearchSelect from "../Form/CustomSearchSelect.vue";
import { getScharffDistrics, getFare } from "../../services/fare"
import InputText from "../Form/InputText.vue";
import DestinationContactButton from "../Button/DestinationContactButton.vue";
import CustomCheckbox from "../Form/CustomCheckbox.vue";
import ErrorMessage from "../ErrorMessage.vue";
import InputPackageAttribute from "../Form/InputPackageAttribute.vue";
import InformativeMessage from "../General/InformativeMessage.vue";
import ButtonDefinitive from "../Button/ButtonDefinitive.vue";
import ShippingCost from "../General/ShippingCost.vue";
import CustomSelect from "../Form/CustomSelect.vue";
import SecondAttempCards from "../Shared/SecondAttempt/SecondAttempCards.vue" 

function debounce(func, wait) {
  let timeout
  return function executedFunction(...args) {
    const later = () => {
      timeout = null
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export default {
  components: {
    InputDistrict,
    FavoriteAddress,

    CustomRadioButton,
    FormSectionTitle,
    CustomSearchSelect,
    InputText,
    DestinationContactButton,
    CustomCheckbox,
    ErrorMessage,
    InputPackageAttribute,
    InformativeMessage,
    ButtonDefinitive,
    ShippingCost,
    SecondAttempCards,
    CustomSelect
  },
  name: "ModalScharffPointPackageV2",
  props: {
    id: String
  },
  data() {
    return {
      typesOfShipments: {
        pointToHome: 'PtH',
        pointToPoint: 'PtP'
      },
      shipmentType: 'PtH',
      destinationDistrictId: '',
      destinationPuntoScharff: '',
      scharffPointsList: [],
      address: '',
      reference: '',
      productDescription: '',
      isAFragileProduct: false,
      maxLength: 120,
      packageWeight: 0,
      packageWidth: 0,
      packageHeight: 0,
      packageDeep: 0,
      packageValue: 0,
      adicionalInformation: '',
      package: {
        hasValueCalculable: false,
        isAddableToList: false
      },
      shippingCostIsLoading: false,
      shippingCost: 0,
      tryToAddPackage: false,
      destinationDistrictData: {},
      selectedScharffPointData: {},
      allowSecondAttempt: false,
      secondAttemptInPS: true,
      aditionalCostCalculated: 0,
      isShippingPaidAtDestination: false,



      isNormal: true,
      puntoScharffId: null,
      lstScharffPoint: [],
      isPointPoint: false,
      map: null,
      isDestinationFocused: false,
      listPackageSize: [
        {
          id: "Pequeno",
          title: "Paquete Pequeño",
          description: "PEQUEÑO",
          imgUrl: "~@/assets/img/icon/icon-small.svg",
          packageSize: {
            height: 10, // Altura
            lengths: 12, // Largo
            width: 25, // Ancho
            maxWeight: 20 // Peso
          }
        },
        {
          id: "Mediano",
          title: "Paquete Mediano",
          description: "MEDIANO",
          imgUrl: "~@/assets/img/icon/icon-medium.svg",
          packageSize: {
            height: 20, // Altura
            lengths: 23, // Largo
            width: 38, // Ancho
            maxWeight: 60
          }
        },
        {
          id: "Grande",
          title: "Paquete Grande",
          description: "GRANDE",
          imgUrl: "~@/assets/img/icon/icon-grand.svg",
          packageSize: {
            height: 35, // Altura
            lengths: 50, // Largo
            width: 52, // Ancho
            maxWeight: 130
          }
        }
      ],
      packageSize: {
        height: 10, // Altura
        lengths: 12, // Largo
        width: 25, // Ancho
        type: "Pequeno",
        weight: 0,
        maxWeight: 20,
      },
      defaultPackageSize: {
        height: 10, // Altura
        lengths: 12, // Largo
        width: 25, // Ancho
        type: "Pequeno",
        weight: 0,
        maxWeight: 20,
      },
      description: null,
      productValue: 0,
      destinationAddress: {
        description: "",
        lat: null,
        lng: null,
        favorite: false,
        additionalAddress: ""
      },
      districtId: 0
    };
  },
  methods: {
    getScharffDistrics,
    setDestinationDistrictData(districtData) {
      if([67, 128].includes(districtData.provinceId)) { // callao y lima
        this.secondAttemptInPS = this.secondAttemptInPS ?? true
        this.allowSecondAttempt = true
      } else {
        this.allowSecondAttempt = false
        this.secondAttemptInPS = null
      }
      this.destinationDistrictData = districtData
    },
    async checkValues() {
      const {
        destinationDistrictId, address, exceedsvolumetricWeight,
        DestinationContact, productDescription, packageWeight, maxLength, exceedsWeightLimit,
        packageWidth, packageHeight, packageDeep, packageValue, hasLimaOrCallaoDistrict,
        destinationPuntoScharff, shipmentType, typesOfShipments, reference
      } = this

      const hasValueCalculable = (
        destinationDistrictId && hasLimaOrCallaoDistrict &&
        packageWeight && !exceedsWeightLimit &&
        packageWidth && packageWidth <= maxLength &&
        packageHeight && packageHeight <= maxLength &&
        packageDeep && packageDeep <= maxLength && !exceedsvolumetricWeight
      )

      // this.package.hasValueCalculable = hasValueCalculable
      // if (hasValueCalculable) await this.calculateCost()
      // else this.shippingCost = 0

      const commonConditions = (
        hasValueCalculable &&
        this.shippingCost > 0 &&
        DestinationContact &&
        productDescription &&
        !this.shippingCostIsLoading &&
        packageValue
      )

      if (shipmentType === typesOfShipments.pointToHome) {
        this.package.isAddableToList = (commonConditions && address.length > 0 && address.length <= 150 && reference.length <= 150)
      }

      if (shipmentType === typesOfShipments.pointToPoint) {
        this.package.isAddableToList = (commonConditions && destinationPuntoScharff)
      }

      console.log('hasValueCalculable', hasValueCalculable)
      console.log('this.shippingCost', this.shippingCost)
      console.log('DestinationContact', DestinationContact)
      console.log('productDescription', productDescription)
      console.log('this.shippingCostIsLoading', this.shippingCostIsLoading)
      console.log('packageValue', packageValue)
      console.log('commonConditions', commonConditions)
      console.log('address', address)
      console.log('reference', reference)
      console.log('this.package.isAddableToList', this.package.isAddableToList)
    },
    async checkValuesToCalculate() {
      const {
        destinationDistrictId, packageWeight, packageWidth, packageHeight, packageDeep,
        hasLimaOrCallaoDistrict, exceedsWeightLimit, maxLength, exceedsvolumetricWeight
      } = this

      const hasValueCalculable = (
        destinationDistrictId && hasLimaOrCallaoDistrict &&
        packageWeight && !exceedsWeightLimit &&
        packageWidth && packageWidth <= maxLength &&
        packageHeight && packageHeight <= maxLength &&
        packageDeep && packageDeep <= maxLength && !exceedsvolumetricWeight
      )

      this.package.hasValueCalculable = hasValueCalculable

      if (hasValueCalculable) await this.calculateCost()
      else this.shippingCost = 0
      this.shippingCostIsLoading = false
    },
    async calculateCost() {
      this.shippingCostIsLoading = true
      try {
        const fare = await getFare({
          "fareItems": [
            {
              "originDistrictId": this.originDistric.districtId.id,
              "destinationDistrictId": this.destinationDistrictId,
              "packageWidth": this.packageWidth,
              "packageHeight": this.packageHeight,
              "packageWeight": this.packageWeight,
              "packageDeep": this.packageDeep,
              "serviceType": 4 // puntos
            }
          ]
        })
        this.shippingCost = fare.data.totalFare
        this.aditionalCostCalculated = fare.data.secondTryFare.toFixed(2) || 0
      } catch (e) {
        this.$swal({
          title: "Error!",
          text: e,
          icon: "error",
        })
      } finally {
        this.shippingCostIsLoading = false
      }
    },
    handleAddPackage() {
      this.tryToAddPackage = true
      if(!this.package.isAddableToList) {
        this.$swal({
          text: '¡Ups! 😅 Parece que hay algunos campos que aún no has completado correctamente.',
          icon: "warning",
          confirmButtonText: "Aceptar"
        })
        return
      }

      const allPacketeData = {
        index: this.$store.getters.SCHARFFPOINT_INDEX_DESTINATION,
        item: {
          description: this.productDescription,
          destinationAddress: {
            additionalAddress: this.reference,
            contact: this.DestinationContact,
            /*
            contact: {
              contactId: 698,
              documentNumber: "12312312",
              email: "josueomar1592+a3@gmail.com",
              name: "Nombres Contacto 3",
              phone: "999333333"
            },
            */
            description: this.address,
            districtId: {
              id: this.destinationDistrictData.id,
              text: this.destinationDistrictData.text,
              textToFinded: this.destinationDistrictData.textToFind,
            },
            lat: null,
            lng: null,
            puntoScharffId: this.selectedScharffPointData
          },
          discountFare: null,
          fare: this.shippingCost,
          secondAttemptInPS: this.secondAttemptInPS,
          isPointPoint: this.shipmentType === this.typesOfShipments.pointToPoint,
          packageSize: {
            height: this.packageHeight,
            lengths: this.packageDeep,
            maxWeight: 15,
            type: null, // lo que era "pequeño", "mediano", etc
            weight: this.packageWeight,
            width: this.packageWidth
          },
          fragil: this.isAFragileProduct,
          isShippingPaidAtDestination: this.isShippingPaidAtDestination,
          productValue: this.packageValue
        },
      }
      this.$store.dispatch("SCHARFFPOINT_ORDER_SET_DESTINATION", allPacketeData);
      const popup = document.getElementById(this.id)
      popup.classList.remove("is-active");

      this.trackEvent('click_puntos_envia_guardar_paquete', this.shipmentType == this.typesOfShipments.pointToHome ? 'dropoff': 'puntoapunto')
    },
    updateSecondAttemptOption(option) {
      this.secondAttemptInPS = option
    },
    async cargarCostoAdicional() {
      const fare = await getFare({
          fareItems: [
            {
              originDistrictId: 3212,
              destinationDistrictId: 3191,
              packageWidth: 1,
              packageHeight: 1,
              packageWeight: 1,
              packageDeep: 1,
              serviceType: 2
            }
          ]
      })
      this.aditionalCostCalculated = fare.data.secondTryFare.toFixed(2) || 0
    },
    async getScharffPoints() {
      this.$store
        .dispatch("GET_SCHARFF_POINT", this.destinationDistrictId)
        .then((response) => {
          this.scharffPointsList = response;
          this.scharffPointsList.forEach((item) => {
            this.map?.addMarkerScharffPoint(item.Location, item.Name, item.Name, item, "setSchaffPointMarker2", "scharffPointMarkerId2");
          })
        })
    },
    setMarketV2(point) {
      const { DepartamentoTexto, ProvinciaTexto, DistritoTexto } = point
      if (this.isShippingPaidAtDestination) {
        this.setDestinationDistrictData({
          departmentId: point.DepartamentoId,
          id: point.DistritoId,
          provinceId: point.ProvinciaId,
          text: utils.methods.toCapitalCase(`${DepartamentoTexto} - ${ProvinciaTexto} - ${DistritoTexto}`),
        })
      }
      this.destinationPuntoScharff = point.ScharffPointId
      this.destinationDistrictId = point.DistritoId
      this.selectedScharffPointData = point
      this.map.centerMap({
        lat: point.Location.Lat,
        lng: point.Location.Lng
      })
    },
    getScharffPointsWithDestinationPayment() {
      this.$store.dispatch('GET_SCHARFF_POINT_WITH_DESTINATION_PAYMENT').then(resp => {
        this.scharffPointsList = resp
        resp.forEach(item => {
          this.map?.addMarkerScharffPoint(item.Location, item.Name, item.Name, item, "setSchaffPointMarker2", "scharffPointMarkerId2")
        })
      })
    },



    validateNormal() {
      if (this.isNormal) {
        this.isPointPoint = false;
      } else {
        this.isPointPoint = true;
      }
    },
    setMarket() {
      this.map.centerMap({
        lat: this.puntoScharffId.Location.Lat,
        lng: this.puntoScharffId.Location.Lng
      });
    },
    async setScharffPointMarkedSelectD() {
      const id = document.getElementById("scharffPointMarkerId2").value;
      let scharffPoint = null;
      this.lstScharffPoint.forEach((item) => {
        if (item.ScharffPointId.toString() === id) {
          scharffPoint = item;
        }
      });
      document.getElementById("scharff-point").value = scharffPoint;
      this.puntoScharffId = scharffPoint;
      await this.$nextTick();
      this.initSelects();
    },
    isNumber(evnt) {
      const evt = evnt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
        return false;
      }
      return true;
    },
    validateAddPackage() {
      if (this.packageSize.weight === null || this.packageSize.weight === 0 || this.packageSize.weight === "0") {
        this.showError("Debe de ingresar el peso del paquete");
        return false;
      }

      if (this.packageSize.weight > this.packageSize.maxWeight) {
        this.showError(`El peso máximo para el paquete ${this.packageSize.type} es de ${this.packageSize.maxWeight} kg`);
        return false;
      }

      if (this.description === null) {
        this.showError("Debe de ingresar la descripcion");
        return false;
      }
      if (this.productValue === null || this.productValue === 0) {
        this.showError("Debe de ingresar el valor del producto");
        return false;
      }
      if (isNaN(this.productValue)){
        this.showError("El valor del producto debe ser numérico");
        return false;
      }

      if (this.DestinationContact === null) {
        this.showError("Debe de ingresar el contacto de destino");
        return false;
      }
      if (!this.isPointPoint && !this.destinationAddress.description) {
        this.showError("Debe de ingresar una dirección");
        return false;
      }
      if (this.isPointPoint && this.puntoScharffId === null) {
        this.showError("Debe de ingresar los datos de envio");
        return false;
      }
      if (this.districtId === 0) {
        this.showError("Debe ingresar el distrito de la lista de opciones ");
        return false;
      }

      return true;
    },
    setModel(origin, destination) {
      const fareRequest = {
        PackageType: destination.packageSize.type === "Pequeno" ? "Pequeño" : destination.packageSize.type,
        PackageValue: destination.productValue,
        AcceptInsurance: false, // No se usa Seguro
        PackageWidth: destination.packageSize.width,
        PackageHeight: destination.packageSize.height,
        PackageWeight: destination.packageSize.weight,
        PackageDeep: destination.packageSize.lengths,
        OriginDistrictId: origin.districtId.id,
        DestinationDistrictId: destination.destinationAddress.districtId.id,
        IsUsingSubscription: false
      };

      return fareRequest;
    },
    saveDestinationAddress() {
      if (this.validateAddPackage()) {
        const destinationTemp = {
          index: this.$store.getters.SCHARFFPOINT_INDEX_DESTINATION,
          item: {
            description: this.description,
            destinationAddress: {
              description: this.destinationAddress.description,
              additionalAddress: this.destinationAddress.additionalAddress,
              lat: this.destinationAddress.lat,
              lng: this.destinationAddress.lng,
              districtId: this.districtId,
              contact: this.DestinationContact,
              puntoScharffId: this.puntoScharffId
            },
            productValue: this.productValue,
            packageSize: this.packageSize,
            fare: 0,
            isPointPoint: this.isPointPoint,
            discountFare: null
          }
        };
        const obj = this.setModel(this.origin, destinationTemp.item);
        const responseFare = getScharffPointFare(obj)
          .then((response) => {
            if (response.status == 200) {
              destinationTemp.item.fare = response.data.totalFare;
              const destination = JSON.parse(JSON.stringify(destinationTemp));
              this.$store.dispatch("SCHARFFPOINT_ORDER_SET_DESTINATION", destination);
            } else {
              this.$swal({
                title: "Error!",
                text: response.data.message,
                icon: "error",
                confirmButtonText: "Aceptar"
              });
            }
          })
          .catch((error) => {
            this.$swal({
              title: "Error!",
              text: error,
              icon: "error",
              confirmButtonText: "Aceptar"
            });
          });

        const btnPopupSlideClose = document.querySelectorAll(".boton-guardar");
        const popupId = btnPopupSlideClose[0].getAttribute("data-popup-id");
        const popup = document.getElementById(popupId);
        popup.classList.remove("is-active");
      }
    },
    setTypePackageSize(item) {
      if (item.id !== "other") {
        this.packageSize.height = item.packageSize.height;
        this.packageSize.lengths = item.packageSize.lengths;
        this.packageSize.width = item.packageSize.width;
        this.packageSize.type = item.id;
        this.packageSize.maxWeight = item.packageSize.maxWeight;
        if (this.packageSize.weight > item.packageSize.maxWeight) {
          this.packageSize.weight = item.packageSize.maxWeight;
        }
      } else {
        this.packageSize.type = "other";
      }
    },
    AddAddressFavorite(type) {
      if (type === "origin") {
        this.$eventBus.$emit("updateAddressInput", this.destinationAddress.description);
        this.$store.dispatch("HELPER_SET_ADD_ADDRESS", this.destinationAddress);
        this.destinationAddress.favorite = true;
      }
      const modalAddAddress = document.querySelector("#modal-add-address");
      const instance = M.Modal.init(modalAddAddress, { dismissible: false });
      instance.open();
    },
    editDestinationContact() {
      this.openContactModal("destination");
    },
    openContactModal(type) {
      this.showLoading();
      this.$store
        .dispatch("GET_CONTACTS")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const contacts = success.data.result.list;
            this.$store.dispatch("GENERAL_SET_CONTACTS", contacts);
            this.$store.dispatch("HELPER_SET_MODAL_CONTACT_TYPE", type);
            const modalContactFavorite = document.querySelector("#modal-contact-destination");
            const instance = M.Modal.init(modalContactFavorite, {
              dismissible: false
            });
            instance.open();
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
    },
    async getScharffPointLst() {
      if (this.districtId.id != undefined && this.districtId.id > 0) {
        this.$store
          .dispatch("GET_SCHARFF_POINT", this.districtId.id)
          .then((response) => {
            this.lstScharffPoint = response;
          })
          .then(() => {
            this.lstScharffPoint.forEach((item) => {
              this.map?.addMarkerScharffPoint(item.Location, item.Name, item.Name, item, "setSchaffPointMarker2", "scharffPointMarkerId2");
            });
            this.initSelects();
          });
      }
    },
    async setDestinationDistrict(id) {
      this.districtId = id;
      if (this.isPointPoint) {
        await this.getScharffPointLst();
      }
    },

    getDestinationAddressData(addressData, placeResult) {
      this.destinationAddress.description = placeResult.formatted_address;
      this.destinationAddress.lat = addressData.latitude;
      this.destinationAddress.lng = addressData.longitude;
      this.isDestinationFocused = false;
      const draggedHandler = (event) => {
        const pos = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        };
        const $this = this;
        this.map.geocodeLocation(pos, (result) => {
          $this.destinationAddress.description = result.formatted;
          $this.destinationAddress.lat = result.position.lat;
          $this.destinationAddress.lng = result.position.lng;
          $this.destinationAddress.favorite = false;
          $this.$refs.destinationAddress.update(result.formatted);
          $this.$swal.close();
        });
      };
      this.map.setMarker({ Lat: addressData.latitude, Lng: addressData.longitude }, null, false, draggedHandler);

      // this.saveDestinationAddress();
      // this.openContactModal("destination");
    },
    getDestinationSelectedAddress(favoriteLocation) {
      this.destinationAddress.description = favoriteLocation.address;
      this.destinationAddress.lat = favoriteLocation.location.Lat;
      this.destinationAddress.lng = favoriteLocation.location.Lng;
      this.$refs.destinationAddress.update(favoriteLocation.address);
      this.isDestinationFocused = false;
      const draggedHandler = (event) => {
        const pos = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        };
        const $this = this;
        this.map.geocodeLocation(pos, (result) => {
          $this.destinationAddress.description = result.formatted;
          $this.destinationAddress.lat = result.position.lat;
          $this.destinationAddress.lng = result.position.lng;
          $this.destinationAddress.favorite = false;
          $this.$refs.destinationAddress.update(result.formatted);
          $this.$swal.close();
        });
      };
      this.map.setMarker(favoriteLocation.location, null, false, draggedHandler);

      // this.saveDestinationAddress();
      // this.openContactModal("destination");
    },
    async clearData() {
      this.$store.dispatch("HELPER_SET_ADD_DESTINATION_CONTACT", null);
      this.shipmentType = this.typesOfShipments.pointToHome
      this.destinationDistrictId = ''
      this.scharffPointsList = []
      this.destinationPuntoScharff = ''
      this.address = ''
      this.reference = ''
      this.productDescription = ''
      this.isAFragileProduct = false
      this.isShippingPaidAtDestination = false
      this.packageWeight = 0
      this.packageWidth = 0
      this.packageHeight = 0
      this.packageDeep = 0
      this.packageValue = 0
      this.secondAttemptInPS = true
      this.adicionalInformation = ''
      this.package = {
        hasValueCalculable: false,
        isAddableToList: false
      }
      this.shippingCostIsLoading = false
      this.shippingCost = 0
      this.tryToAddPackage = false
      this.destinationDistrictData = {}
      this.selectedScharffPointData = {}
    },
    async setData(destination) {
      this.$store.dispatch("HELPER_SET_ADD_DESTINATION_CONTACT", destination.destinationAddress.contact);
      this.shipmentType = destination.isPointPoint ? this.typesOfShipments.pointToPoint : this.typesOfShipments.pointToHome
      this.isShippingPaidAtDestination = destination.isShippingPaidAtDestination
      this.destinationPuntoScharff = destination.destinationAddress.puntoScharffId?.ScharffPointId || ''
      this.selectedScharffPointData = destination.destinationAddress.puntoScharffId
      this.address = destination.destinationAddress.description
      this.reference = destination.destinationAddress.additionalAddress
      this.productDescription = destination.description
      this.packageWeight = destination.packageSize.weight
      this.packageWidth = destination.packageSize.height
      this.packageHeight = destination.packageSize.lengths
      this.packageDeep = destination.packageSize.width
      this.packageValue = destination.productValue
      this.secondAttemptInPS = destination.secondAttemptInPS
      this.isAFragileProduct = destination.fragil
      this.package = {
        hasValueCalculable: true,
        isAddableToList: true
      }
      this.shippingCostIsLoading = false
      this.shippingCost = destination.fare
      this.tryToAddPackage = false

      setTimeout(() => {
        if (!destination.isShippingPaidAtDestination) {
          this.$refs.CustomSelectComponentRef.selectElementFromOutside(destination.destinationAddress.districtId)
        }
        if (destination.isPointPoint && destination.isShippingPaidAtDestination) {
          this.setMarketV2(destination.destinationAddress.puntoScharffId)
        }
      }, 100)
    },
    async validateMarkers() {
      if (this.isPointPoint) {
        this.isNormal = false;
        this.map.clearMarkers();
        this.map.clearMarkersScharffPoint();
        if(this.districtId >=0){
          await this.getScharffPointLst();
        }
      } else {
        this.isNormal = true;
        this.map.clearMarkers();
        this.puntoScharffId = null;
        this.map.clearMarkersScharffPoint();
        this.map.setMarker(
          {
            Lat: this.destinationAddress.lat,
            Lng: this.destinationAddress.lng
          },
          null,
          true
        );
      }
    }
  },
  created () {
    this.cargarCostoAdicional()
    this.$watch(() => [
      this.destinationDistrictId,
      this.destinationPuntoScharff,
      this.address,
      this.DestinationContact,
      this.productDescription,
      this.packageWeight,
      this.packageWidth,
      this.packageHeight,
      this.packageDeep,
      this.packageValue,
      this.shippingCost,
    ], () => {
      this.checkValues()
    })
    this.$watch(() => [
      this.destinationDistrictId,
      this.packageWeight,
      this.packageWidth,
      this.packageHeight,
      this.packageDeep,
    ], debounce(this.checkValuesToCalculate, 300))
    this.$watch(() => [
      this.destinationDistrictId,
      this.packageWeight,
      this.packageWidth,
      this.packageHeight,
      this.packageDeep,
    ],
    () => {
      this.shippingCostIsLoading = true
    })
    this.$watch('destinationDistrictId', value => {
      if (value && !this.isShippingPaidAtDestination) {
        this.getScharffPoints()
      }
    })
    this.$watch('isShippingPaidAtDestination', value => {
      this.destinationDistrictId = ''
      this.destinationPuntoScharff = ''
      this.destinationDistrictData = {}
      this.selectedScharffPointData = {}
      if(value) {
        this.getScharffPointsWithDestinationPayment()
      } else {
        this.scharffPointsList = []
      }
    })
  },
  mounted() {
    this.map = new Map();
    this.map.initMap("mp_ps_type1");
    this.initSelects();
    this.$eventBus.$on("scharffPointClearDataDestinationModal", () => {
      this.clearData();
    });

    this.$eventBus.$on("scharffPointSetDataDestinationModal", (destination) => {
      this.setData(destination);
    });
  },
  computed: {
    hasLimaOrCallaoDistrict() {
      const originDistrict = `${this.originDistric.districtId?.text || ''}`
      const destinationDistrict = this.destinationDistrictData?.text || ''
      const originAndDestinationDistricts = `${originDistrict}${destinationDistrict}`
      return originDistrict && destinationDistrict ? originAndDestinationDistricts.toLowerCase().includes('lima') || originAndDestinationDistricts.toLowerCase().includes('callao') : true
    },
    originDistric() {
      return this.$store.getters.SCHARFFPOINT_ORDER_ORIGIN;
    },
    exceedsWeightLimit() {
      return this.packageWeight > 15
    },
    volumetricWeight() {
      const a = this.packageWidth
      const b = this.packageHeight
      const c = this.packageDeep
      return a * b * c
    },
    exceedsvolumetricWeight() {
      const volumn = this.volumetricWeight
      return volumn > 75000
    },
    volumetricWeigthMsg() {
      const formatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }); 
      const maxValue = 75000
      const currentValue = this.volumetricWeight
      return `El volumen máximo por paquete en el servicio Puntos Sharf es ${formatter.format(maxValue)} cm³. Has ingresado un volumen de ${formatter.format(currentValue)} cm³.`
    },
    DestinationContact() {
      return this.$store.getters.GENERAL_DESTINATION_CONTACT;
    },

    origin() {
      return this.$store.getters.SCHARFFPOINT_ORDER_ORIGIN;
    },
    amountMax() {
      return this.productValue > 1000;
    },
    minWight() {
      return 1;
    },
    maxWeight() {
      return this.packageSize.maxWeight;
    },
  },
  watch:{
    shipmentType(value){
      if(value == this.typesOfShipments.pointToHome){
        this.trackEvent('click_puntos_envia_paso_3_a_domicilio', 'dropoff')
        this.isShippingPaidAtDestination = false
      }else{
        this.trackEvent('click_puntos_envia_paso_3_a_punto', 'puntoapunto')
      }
    }
  }
};
</script>

<style>
@import '../../styles/09-componentsv2/addPackage.scss';






[data-tooltip],
.tooltip {
  position: relative;
  cursor: pointer;
}

/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
  position: absolute;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}

/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Base styles for the tooltip's directional arrow */
.tooltip:before,
[data-tooltip]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltip]:after {
  z-index: 1000;
  padding: 8px;
  width: 160px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  font-size: 14px;
  line-height: 1.2;
}

/* Directions */

/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after,
.tooltip-top:before,
.tooltip-top:after {
  bottom: 100%;
  left: 50%;
}

[data-tooltip]:before,
.tooltip:before,
.tooltip-top:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: #000;
  border-top-color: hsla(0, 0%, 20%, 0.9);
}

/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip:after,
.tooltip-top:after {
  margin-left: -80px;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after {
  -webkit-transform: translateY(-12px);
  -moz-transform: translateY(-12px);
  transform: translateY(-12px);
}

/* Left */
.tooltip-left:before,
.tooltip-left:after {
  right: 100%;
  bottom: 50%;
  left: auto;
}

.tooltip-left:before {
  margin-left: 0;
  margin-right: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-left-color: #000;
  border-left-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-left:hover:before,
.tooltip-left:hover:after,
.tooltip-left:focus:before,
.tooltip-left:focus:after {
  -webkit-transform: translateX(-12px);
  -moz-transform: translateX(-12px);
  transform: translateX(-12px);
}

/* Bottom */
.tooltip-bottom:before,
.tooltip-bottom:after {
  top: 100%;
  bottom: auto;
  left: 50%;
}

.tooltip-bottom:before {
  margin-top: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-bottom-color: #000;
  border-bottom-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-bottom:hover:before,
.tooltip-bottom:hover:after,
.tooltip-bottom:focus:before,
.tooltip-bottom:focus:after {
  -webkit-transform: translateY(12px);
  -moz-transform: translateY(12px);
  transform: translateY(12px);
}

/* Right */
.tooltip-right:before,
.tooltip-right:after {
  bottom: 50%;
  left: 100%;
}

.tooltip-right:before {
  margin-bottom: 0;
  margin-left: -12px;
  border-top-color: transparent;
  border-right-color: #000;
  border-right-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-right:hover:before,
.tooltip-right:hover:after,
.tooltip-right:focus:before,
.tooltip-right:focus:after {
  -webkit-transform: translateX(12px);
  -moz-transform: translateX(12px);
  transform: translateX(12px);
}

/* Move directional arrows down a bit for left/right tooltips */
.tooltip-left:before,
.tooltip-right:before {
  top: 3px;
}

/* Vertically center tooltip content for left/right tooltips */
.tooltip-left:after,
.tooltip-right:after {
  margin-left: 0;
  margin-bottom: -16px;
}
</style>
