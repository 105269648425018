<!-- eslint-disable max-len -->
<template>
  <div class="c-popup-slide c-popup-slide--right" :id="id">
    <div class="c-popup-slide__backdrop c-btn-popup-slide-close" :data-popup-id="id"></div>
    <div class="c-popup-slide__container px-0">
      <!-- @start Header-->
      <div class="c-popup-slide__header">
        <div class="c-popup-slide__content">
          <h3>Agregar paquete</h3>
        </div>
        <div class="close c-popup-slide__close">
          <img class="c-btn-popup-slide-close" src="~@/assets/img/icon/icon-closex.svg" :data-popup-id="id" />
        </div>
      </div>
      <!-- @end Header-->
      <!-- @start content-->
      <div class="c-popup-slide__body">
        <div class="c-popup-slide__body__container">
          <div class="c-popup-slide__content c-scrollbar">
            <div class="content-information-the-package">
              <!-- @start location delivery-->
              <div class="content-package-delivery">
                <h4>1) Seleccionar el destino/modalidad</h4>
                <div class="c-switch switch">
                  <label class="row">
                    <div class="col s2"><input value="false" type="checkbox" v-model="isNormal" v-on:change="validateNormal()" /><span class="lever"></span></div>
                    <div class="col s10">
                      <div class="c-switch__text">Envio a domicilio</div>
                    </div>
                  </label>
                </div>
                <div class="c-switch switch">
                  <label class="row">
                    <div class="col s2"><input value="true" type="checkbox" v-model="isPointPoint" v-on:change="validateMarkers()" /><span class="lever"></span></div>
                    <div class="col s10">
                      <div class="c-switch__text">
                        Envío de Punto a Punto
                        <a
                          href="#"
                          class="tooltip-bottom"
                          data-tooltip="¡Ahora podrás enviar/recoger tus paquetes desde un Punto Sharf a otro Punto Sharf!"
                          ><img
                            src="../../assets/img/icon/icon-help.svg"
                            width="15"
                            style="
                              position: absolute;
                              right: -21px;
                              top: 2px;
                            " />
                          <img
                            src="../../assets/img/icon/nuevo.png"
                            width="40"
                            style="
                              position: absolute;
                              right: -100px;
                              top: -10px;
                            "
                        /></a>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div class="content-package-delivery" style="margin-top: 20px">
                <h4>2) Indicar lugar de entrega</h4>
                <input-district id="scharffPoint-step-3-modal-pop" text="Buscar distrito" @getDistrictId="setDestinationDistrict" @input="districtId = 0" serviceType="2" @focus="isDestinationFocused = false"> </input-district>
                <div class="c-select input-field curso-pointer" v-show="isPointPoint">
                  <select id="scharff-point" v-model="puntoScharffId" @change="setMarket()">
                    <option v-for="item in lstScharffPoint" v-bind:value="item" v-bind:key="item.ScharffPointId">
                      {{ item.Name }}
                    </option>
                  </select>
                  <label for="" class="select-label">Punto Sharf</label>
                </div>

                <div class="c-input input-field curso-pointer" v-show="!isPointPoint">
                  <!-- <vue-google-autocomplete
                    ref="destinationAddress"
                    id="c-focus-open-modal-search-direction-destination"
                    class="curso-pointer"
                    placeholder
                    v-on:placechanged="getDestinationAddressData"
                    country="pe"
                    v-on:focus="isDestinationFocused = true"
                  ></vue-google-autocomplete> -->
                  <input ref="destinationAddress" type="text" v-model="destinationAddress.description" @focus="isDestinationFocused = true" max="150" maxlength="150" />

                  <label for>Dirección</label>
                  <div id="c-icon-favorite-1" class="c-input--icon btnOpenModalDirecitionClass" v-on:click="AddAddressFavorite('destination', null)">
                    <img v-if="destinationAddress.favorite === true" src="~@/assets/img/icon/icon-estrella-on.svg" alt="favorite" loading="lazy" class="btn-open-modal-direction" />
                    <img v-else src="~@/assets/img/icon/icon-estrella-set.svg" alt="favorite" loading="lazy" class="btn-open-modal-direction" />
                  </div>
                </div>

                <div v-show="!isPointPoint" class="c-input input-field mb-2">
                  <input
                    type="text"
                    v-model="destinationAddress.additionalAddress"
                    placeholder="Acá puedes colocar todo el detalle de la dirección, incluyendo MZ o Lote"
                    @focus="isDestinationFocused = false"
                    max="299"
                    maxlength="299"
                  />
                  <label for class="active">Referencia</label>
                </div>

                <favorite-address id="favoritePackageAddress" v-show="isDestinationFocused === true" @getSelectedAddress="getDestinationSelectedAddress" modalId="modal-contact-destination" />

                <div v-show="isPointPoint" class="c-popup-slide__map" id="mp_ps_type1"></div>
              </div>

              <div class="content-package-delivery">
                <h4>3) Datos de quien recibe</h4>
                <article v-if="DestinationContact === null" id="btnModalContactFavorite" class="c-grid--services__item pt-0 pb-0">
                  <a @click="openContactModal('destination')">
                    <div id="" class="card c-card c-card--service-box d-flex p-1">
                      <div class="card-image">
                        <div class="card-title color-text-black"><span class="card-sub-title color-text-black">Escoge tu</span><span class="card-option">contacto</span></div>
                      </div>
                      <img src="@/assets/img/service-point-sharff-3.svg" class="translatex" />
                    </div>
                  </a>
                </article>
                <div v-if="DestinationContact !== null" id="c-contact-send-2" class="c-btn-contact-click c-content--spacing-block">
                  <div class="collection c-collection c-collection--not-hover c-collection--card">
                    <div class="collection-item collection-item--arrow-next collection-item--icon">
                      <a @click="editDestinationContact()" class="collection-item--icon collection-item--icon--user">
                        <div class="c-text-medium font-weight-bold">
                          {{ DestinationContact.name }}
                        </div>
                        <div class="c-text-medium">
                          {{ DestinationContact.phone }}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="content-package-tab-result">
                <h4>4) Tamaño del paquete</h4>
                <article class="c-grid--services__item">
                  <div class="c-section--content-package">
                    <ul id="navList" class="navList c-nav--tab-d-c d-flex justify-content-between m-0 w-100 tabs h-100 h-100 c-bg-none">
                      <li v-for="item in listPackageSize" v-bind:key="item.id" class="c-tab-button item-package tab h-100 line-height-initial">
                        <a
                          :href="'#' + item.id"
                          v-on:click="setTypePackageSize(item)"
                          v-bind:class="{
                            active: item.id === packageSize.type,
                            'active-package': item.id === packageSize.type
                          }"
                          class="item-package-proof w-100 color-black"
                        >
                          <img v-if="item.id === 'Pequeno' || item.id === 'Pequeño'" src="~@/assets/img/icon/icon-small.svg" alt="" />
                          <img v-if="item.id === 'Mediano'" src="~@/assets/img/icon/icon-medium.svg" alt="" />
                          <img v-if="item.id === 'Grande'" src="~@/assets/img/icon/icon-grand.svg" alt="" />
                          <img v-if="item.id === 'Personalizado'" src="~@/assets/img/icon/icon-small.svg" alt="" />
                          <p>{{ item.description }}</p>
                        </a>
                      </li>
                      <li class="indicator" style="left: 0px; right: 419px"></li>
                    </ul>
                  </div>
                </article>
              </div>
              <div class="content-package-information-result">
                <div
                  v-for="item in listPackageSize"
                  v-bind:key="item.id"
                  :id="item.id"
                  class="c-content--box-shadow mb-1"
                  v-show="item.id === packageSize.type"
                >
                  <template v-if="packageSize.type == item.id">
                    <article class="c-grid--services__item c-section--dateContent">
                      <div class="c-content--information-package">
                        <h3>{{ item.title }}</h3>
                        <!-- <img v-if="item.id === 'Pequeno' || item.id === 'Pequeño'" src="~@/assets/img/icon/icon-small.svg"
                        alt="" width="63px" height="56px" />
                      <img v-if="item.id === 'Mediano'" src="~@/assets/img/icon/icon-medium.svg" alt="" width="63px"
                        height="56px" />
                      <img v-if="item.id === 'Grande'" src="~@/assets/img/icon/icon-grand.svg" alt="" width="63px"
                        height="56px" />
                      <img v-if="item.id === 'Personalizado'" src="~@/assets/img/icon/icon-other.svg" alt=""
                        width="63px" height="56px" /> -->
                      </div>
                    </article>
                    <article class="c-grid--services__item c-section--dateContent">
                      <div v-if="item.id !== 'Personalizado'" class="c-section--content-package-information">
                        <div class="item-package-information">
                          Alto:
                          <p>{{ item.packageSize.height }}cm</p>
                        </div>
                        <div class="item-package-information">
                          Ancho:
                          <p>{{ item.packageSize.width }}cm</p>
                        </div>
                        <div class="item-package-information">
                          Largo:
                          <p>{{ item.packageSize.lengths }}cm</p>
                        </div>
                      </div>
                      <div v-else>
                        <div class="row m-0">
                          <div class="col s4">
                            <div class="c-input input-field">
                              <input type="text" v-model="packageSize.height" @keypress="isNumber($event)" maxlength="4" />
                              <label for="" class="active">Alto</label>
                            </div>
                          </div>
                          <div class="col s4">
                            <div class="c-input input-field">
                              <input type="text" v-bind:value="packageSize.width" @keypress="isNumber($event)" maxlength="4" v-on:input="packageSize.width = $event.target.value" />
                              <label for="" class="active">Ancho</label>
                            </div>
                          </div>
                          <div class="col s4">
                            <div class="c-input input-field">
                              <input type="text" v-model="packageSize.lengths" maxlength="4" @keypress="isNumber($event)" />
                              <label for="" class="active">Largo</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article class="c-grid--services__item c-section--dateContent">
                      <div class="c-content--range d-flex justify-content-between">
                        <!-- <form action="#" class="w-80">
                        <label for="">Peso (kg)</label>
                        <p class="range-field">
                          <input
                            v-model="packageSize.weight"
                            type="range"
                            id="test5"
                            min="0"
                            max="130"
                            class="m-0"
                          /><span class="thumb"
                            ><span class="value"></span
                          ></span>
                        </p>
                      </form> -->
                        <div class="c-modal--directions-body content w-40">
                          <label for="">Peso (kg)</label>
                          <div class="c-input input-field">
                            <input type="number" :min="minWight" :max="maxWeight" v-model="packageSize.weight" />
                            <label for class="font-weight-light active">kg</label>
                          </div>
                        </div>
                      </div>
                    </article>
                    <article class="c-grid--services__item c-section--dateContent">
                      <div class="c-modal--directions-body">
                        <div class="c-input input-field">
                          <input type="text" v-model="description" />
                          <label for="" class="active">Descripción del producto a enviar</label>
                        </div>
                      </div>
                      <div class="c-modal--directions-body">
                        <div class="c-input input-field">
                          <input
                            type="number"
                            v-model="productValue"
                            @keypress="isNumber($event)"
                          />
                          <label for="" class="active"
                            >Valor del producto (S/)</label
                          >
                        </div>
                      </div>
                    </article>
                  </template>
                </div>
                <article class="c-grid--services__item c-section--dateContent">
                  <div class="c-section--incognito mt-2" data-position="top" data-tooltip="Recuerda que el seguro de este servicio cubre hasta S/700. Te recomendamos enviar productos que tengan hasta ese valor como máximo.">
                    <img src="~@/assets/img/icon/icon-exclamation-black.svg" alt="" class="mr-1" />Tenemos garantía de hasta
                    <span class="font-weight-bold">S/700 soles</span>
                  </div>
                  <div class="c-section--incognito icongnito-warning" v-if="amountMax"><img src="~@/assets/img/icon/icon-exclamation-red.svg" alt="" class="mr-1" />Haz excedido el monto permitido, de continuar aceptas nuestras restricciones</div>
                </article>
                <article class="c-grid--services__item c-section--dateContent" v-if="false">
                  <div class="m-0 d-flex justify-content-center">
                    <a id="btnOpenModalRestriction" class="c-button c-button--five c-button--mobile-small waves-effect restriccion"> Ver restricciones<img src="~@/assets/img/icon/icon-danger.svg" alt="" /></a>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-popup-slide__footer">
        <div class="c-nav c-nav--floating-footer-sticky__spaceButtonPair">
          <a :data-popup-id="id" @click="saveDestinationAddress()" class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect boton-guardar">Guardar</a>
          <button :data-popup-id="id" class="c-button c-button--width-fuid c-button--mobile-small waves-effect c-button--flat c-btn-popup-slide-close">Salir</button>
        </div>
      </div>
    </div>
    <button id="setSchaffPointMarker2" v-on:click="setScharffPointMarkedSelectD()" style="display: none"></button>
    <input id="scharffPointMarkerId2" style="display: none" />
  </div>
</template>

<script>
import M from "materialize-css";
import InputDistrict from "../General/InputDistrict.vue";
import FavoriteAddress from "../General/FavoriteAddress.vue";
import Map from "../../commons/mixins/maps";
import { nextTick } from "process";

export default {
  components: {
    InputDistrict,
    FavoriteAddress
  },
  name: "ModalScharffPointPackage",
  props: {
    id: String
  },
  data() {
    return {
      isNormal: true,
      puntoScharffId: null,
      lstScharffPoint: [],
      isPointPoint: false,
      map: null,
      isDestinationFocused: false,
      listPackageSize: [
        {
          id: "Pequeno",
          title: "Paquete Pequeño",
          description: "PEQUEÑO",
          imgUrl: "~@/assets/img/icon/icon-small.svg",
          packageSize: {
            height: 10, // Altura
            lengths: 12, // Largo
            width: 25, // Ancho
            maxWeight: 20 // Peso
          }
        },
        {
          id: "Mediano",
          title: "Paquete Mediano",
          description: "MEDIANO",
          imgUrl: "~@/assets/img/icon/icon-medium.svg",
          packageSize: {
            height: 20, // Altura
            lengths: 23, // Largo
            width: 38, // Ancho
            maxWeight: 60
          }
        },
        {
          id: "Grande",
          title: "Paquete Grande",
          description: "GRANDE",
          imgUrl: "~@/assets/img/icon/icon-grand.svg",
          packageSize: {
            height: 35, // Altura
            lengths: 50, // Largo
            width: 52, // Ancho
            maxWeight: 130
          }
        }
      ],
      packageSize: {
        height: 10, // Altura
        lengths: 12, // Largo
        width: 25, // Ancho
        type: "Pequeno",
        weight: 0,
        maxWeight: 20,
      },
      defaultPackageSize: {
        height: 10, // Altura
        lengths: 12, // Largo
        width: 25, // Ancho
        type: "Pequeno",
        weight: 0,
        maxWeight: 20,
      },
      description: null,
      productValue: 0,
      destinationAddress: {
        description: "",
        lat: null,
        lng: null,
        favorite: false,
        additionalAddress: ""
      },
      districtId: 0
    };
  },
  methods: {
    validateNormal() {
      if (this.isNormal) {
        this.isPointPoint = false;
      } else {
        this.isPointPoint = true;
      }
    },
    setMarket() {
      this.map.centerMap({
        lat: this.puntoScharffId.Location.Lat,
        lng: this.puntoScharffId.Location.Lng
      });
    },
    async setScharffPointMarkedSelectD() {
      const id = document.getElementById("scharffPointMarkerId2").value;
      let scharffPoint = null;
      this.lstScharffPoint.forEach((item) => {
        if (item.ScharffPointId.toString() === id) {
          scharffPoint = item;
        }
      });
      document.getElementById("scharff-point").value = scharffPoint;
      this.puntoScharffId = scharffPoint;
      await this.$nextTick();
      this.initSelects();
    },
    isNumber(evnt) {
      const evt = evnt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
        return false;
      }
      return true;
    },
    validateAddPackage() {
      if (this.packageSize.weight === null || this.packageSize.weight === 0 || this.packageSize.weight === "0") {
        this.showError("Debe de ingresar el peso del paquete");
        return false;
      }

      if (this.packageSize.weight > this.packageSize.maxWeight) {
        this.showError(`El peso máximo para el paquete ${this.packageSize.type} es de ${this.packageSize.maxWeight} kg`);
        return false;
      }

      if (this.description === null) {
        this.showError("Debe de ingresar la descripcion");
        return false;
      }
      if (this.productValue === null || this.productValue === 0) {
        this.showError("Debe de ingresar el valor del producto");
        return false;
      }
      if (isNaN(this.productValue)){
        this.showError("El valor del producto debe ser numérico");
        return false;
      }

      if (this.DestinationContact === null) {
        this.showError("Debe de ingresar el contacto de destino");
        return false;
      }
      if (!this.isPointPoint && !this.destinationAddress.description) {
        this.showError("Debe de ingresar una dirección");
        return false;
      }
      if (this.isPointPoint && this.puntoScharffId === null) {
        this.showError("Debe de ingresar los datos de envio");
        return false;
      }
      if (this.districtId === 0) {
        this.showError("Debe ingresar el distrito de la lista de opciones ");
        return false;
      }

      return true;
    },
    setModel(origin, destination) {
      const fareRequest = {
        PackageType: destination.packageSize.type === "Pequeno" ? "Pequeño" : destination.packageSize.type,
        PackageValue: destination.productValue,
        AcceptInsurance: false, // No se usa Seguro
        PackageWidth: destination.packageSize.width,
        PackageHeight: destination.packageSize.height,
        PackageWeight: destination.packageSize.weight,
        PackageDeep: destination.packageSize.lengths,
        OriginDistrictId: origin.districtId.id,
        DestinationDistrictId: destination.destinationAddress.districtId.id,
        IsUsingSubscription: false
      };

      // const fareRequest = {
      //   PackageValue: destination.productValue,
      //   PackageWidth: destination.packageSize.width,
      //   PackageHeight: destination.packageSize.height,
      //   PackageDeep: destination.packageSize.lengths,
      //   PackageWeight: destination.packageSize.weight,
      //   PackageType:
      //     destination.packageSize.type === "Pequeno"
      //       ? "Pequeño"
      //       : destination.packageSize.type,
      //   isMoreThan10: false,
      //   IsServiceExpress: false,
      //   IsServiceProgrammed: false,
      //   isServiceScharffPoint: true,
      //   OriginDistrictId: origin.districtId.id,
      //   DestinationDistrictId: destination.destinationAddress.districtId.id,
      //   ProgrammedServiceDate: null,
      //   IsUsingSubscription: false,
      // };
      return fareRequest;
    },
    saveDestinationAddress() {
      if (this.validateAddPackage()) {
        const destinationTemp = {
          index: this.$store.getters.SCHARFFPOINT_INDEX_DESTINATION,
          item: {
            description: this.description,
            destinationAddress: {
              description: this.destinationAddress.description,
              additionalAddress: this.destinationAddress.additionalAddress,
              lat: this.destinationAddress.lat,
              lng: this.destinationAddress.lng,
              districtId: this.districtId,
              contact: this.DestinationContact,
              puntoScharffId: this.puntoScharffId
            },
            productValue: this.productValue,
            packageSize: this.packageSize,
            fare: 0,
            isPointPoint: this.isPointPoint,
            discountFare: null
          }
        };
        const obj = this.setModel(this.origin, destinationTemp.item);
        const responseFare = getScharffPointFare(obj)
          .then((response) => {
            if (response.status == 200) {
              destinationTemp.item.fare = response.data.totalFare;
              const destination = JSON.parse(JSON.stringify(destinationTemp));
              this.$store.dispatch("SCHARFFPOINT_ORDER_SET_DESTINATION", destination);
            } else {
              this.$swal({
                title: "Error!",
                text: response.data.message,
                icon: "error",
                confirmButtonText: "Aceptar"
              });
            }
          })
          .catch((error) => {
            this.$swal({
              title: "Error!",
              text: error,
              icon: "error",
              confirmButtonText: "Aceptar"
            });
          });
        // this.$store
        //   .dispatch("SCHARFFPOINT_CALCULATE_FARE", obj)
        //   .then((response) => {
        //     destinationTemp.item.fare = response.totalFare;
        //     const destination = JSON.parse(JSON.stringify(destinationTemp));

        //     this.$store.dispatch(
        //       "SCHARFFPOINT_ORDER_SET_DESTINATION",
        //       destination
        //     );
        //   })
        //   .catch((error) => {
        //     this.$swal({
        //       title: "Error!",
        //       text: error,
        //       icon: "error",
        //       confirmButtonText: "Aceptar",
        //     });
        //   });

        const btnPopupSlideClose = document.querySelectorAll(".boton-guardar");
        const popupId = btnPopupSlideClose[0].getAttribute("data-popup-id");
        const popup = document.getElementById(popupId);
        popup.classList.remove("is-active");
      }
    },
    setTypePackageSize(item) {
      if (item.id !== "other") {
        this.packageSize.height = item.packageSize.height;
        this.packageSize.lengths = item.packageSize.lengths;
        this.packageSize.width = item.packageSize.width;
        this.packageSize.type = item.id;
        this.packageSize.maxWeight = item.packageSize.maxWeight;
        if (this.packageSize.weight > item.packageSize.maxWeight) {
          this.packageSize.weight = item.packageSize.maxWeight;
        }
      } else {
        this.packageSize.type = "other";
      }
    },
    AddAddressFavorite(type) {
      if (type === "origin") {
        this.$eventBus.$emit("updateAddressInput", this.destinationAddress.description);
        this.$store.dispatch("HELPER_SET_ADD_ADDRESS", this.destinationAddress);
        this.destinationAddress.favorite = true;
      }
      const modalAddAddress = document.querySelector("#modal-add-address");
      const instance = M.Modal.init(modalAddAddress, { dismissible: false });
      instance.open();
    },
    editDestinationContact() {
      this.openContactModal("destination");
    },
    openContactModal(type) {
      this.showLoading();
      this.$store
        .dispatch("GET_CONTACTS")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const contacts = success.data.result.list;
            this.$store.dispatch("GENERAL_SET_CONTACTS", contacts);
            this.$store.dispatch("HELPER_SET_MODAL_CONTACT_TYPE", type);
            const modalContactFavorite = document.querySelector("#modal-contact");
            const instance = M.Modal.init(modalContactFavorite, {
              dismissible: false
            });
            instance.open();
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
    },
    async getScharffPointLst() {
      if (this.districtId.id != undefined && this.districtId.id > 0) {
        this.$store
          .dispatch("GET_SCHARFF_POINT", this.districtId.id)
          .then((response) => {
            this.lstScharffPoint = response;
          })
          .then(() => {
            this.lstScharffPoint.forEach((item) => {
              this.map?.addMarkerScharffPoint(item.Location, item.Name, item.Name, item, "setSchaffPointMarker2", "scharffPointMarkerId2");
            });
            this.initSelects();
          });
      }
    },
    async setDestinationDistrict(id) {
      this.districtId = id;
      if (this.isPointPoint) {
        await this.getScharffPointLst();
      }
    },

    getDestinationAddressData(addressData, placeResult) {
      this.destinationAddress.description = placeResult.formatted_address;
      this.destinationAddress.lat = addressData.latitude;
      this.destinationAddress.lng = addressData.longitude;
      this.isDestinationFocused = false;
      const draggedHandler = (event) => {
        const pos = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        };
        const $this = this;
        this.map.geocodeLocation(pos, (result) => {
          $this.destinationAddress.description = result.formatted;
          $this.destinationAddress.lat = result.position.lat;
          $this.destinationAddress.lng = result.position.lng;
          $this.destinationAddress.favorite = false;
          $this.$refs.destinationAddress.update(result.formatted);
          $this.$swal.close();
        });
      };
      this.map.setMarker({ Lat: addressData.latitude, Lng: addressData.longitude }, null, false, draggedHandler);

      // this.saveDestinationAddress();
      // this.openContactModal("destination");
    },
    getDestinationSelectedAddress(favoriteLocation) {
      this.destinationAddress.description = favoriteLocation.address;
      this.destinationAddress.lat = favoriteLocation.location.Lat;
      this.destinationAddress.lng = favoriteLocation.location.Lng;
      this.$refs.destinationAddress.update(favoriteLocation.address);
      this.isDestinationFocused = false;
      const draggedHandler = (event) => {
        const pos = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        };
        const $this = this;
        this.map.geocodeLocation(pos, (result) => {
          $this.destinationAddress.description = result.formatted;
          $this.destinationAddress.lat = result.position.lat;
          $this.destinationAddress.lng = result.position.lng;
          $this.destinationAddress.favorite = false;
          $this.$refs.destinationAddress.update(result.formatted);
          $this.$swal.close();
        });
      };
      this.map.setMarker(favoriteLocation.location, null, false, draggedHandler);

      // this.saveDestinationAddress();
      // this.openContactModal("destination");
    },
    async clearData() {
      this.packageSize = { ...this.defaultPackageSize };
      this.description = null;
      this.productValue = null;
      this.districtId = 0;
      this.$refs.districtId = null;
      this.$refs.destinationAddress = null;
      this.destinationAddress.additionalAddress = null
      this.destinationAddress.description = null;
      this.map.clearMarkers();
      this.$store.dispatch("HELPER_SET_ADD_DESTINATION_CONTACT", null);
      this.isPointPoint = false;
      this.puntoScharffId = null;
      this.isDestinationFocused = false;
    },
    async setData(destination) {
      this.isPointPoint = destination.isPointPoint;
      this.packageSize.height = destination.packageSize.height;
      this.packageSize.lengths = destination.packageSize.lengths;
      this.packageSize.width = destination.packageSize.width;
      this.packageSize.type = destination.packageSize.type === "Pequeño" ? "Pequeno" : destination.packageSize.type;
      this.packageSize.weight = destination.packageSize.weight;
      this.packageSize.maxWeight = destination.packageSize.maxWeight;
      this.description = destination.description;
      this.productValue = destination.productValue;
      this.districtId = destination.destinationAddress.districtId;
      this.destinationAddress.description = destination.destinationAddress.description;
      this.destinationAddress.lat = destination.destinationAddress.lat;
      this.destinationAddress.lng = destination.destinationAddress.lng;
      this.puntoScharffId = destination.destinationAddress.puntoScharffId;
      if (this.isPointPoint) {
        await this.getScharffPointLst();
        this.initSelects();
        this.isNormal = false;
      } else {
        this.map.setMarker(
          {
            Lat: destination.destinationAddress.lat,
            Lng: destination.destinationAddress.lng
          },
          null,
          true
        );
      }

      this.$store.dispatch("HELPER_SET_ADD_DESTINATION_CONTACT", destination.destinationAddress.contact);
      this.destinationAddress.additionalAddress = destination.destinationAddress.additionalAddress;
      this.$refs.destinationAddress.update(destination.destinationAddress.description);
      this.isDestinationFocused = false;
      this.initSelects();
    },
    async validateMarkers() {
      if (this.isPointPoint) {
        this.isNormal = false;
        this.map.clearMarkers();
        this.map.clearMarkersScharffPoint();
        if(this.districtId >=0){
          await this.getScharffPointLst();
        }
      } else {
        this.isNormal = true;
        this.map.clearMarkers();
        this.puntoScharffId = null;
        this.map.clearMarkersScharffPoint();
        this.map.setMarker(
          {
            Lat: this.destinationAddress.lat,
            Lng: this.destinationAddress.lng
          },
          null,
          true
        );
      }
    }
  },
  mounted() {
    this.map = new Map();
    this.map.initMap("mp_ps_type1");
    this.initSelects();
    this.$eventBus.$on("scharffPointClearDataDestinationModal", () => {
      this.clearData();
    });

    this.$eventBus.$on("scharffPointSetDataDestinationModal", (destination) => {
      this.setData(destination);
    });
  },
  computed: {
    DestinationContact() {
      return this.$store.getters.GENERAL_DESTINATION_CONTACT;
    },
    origin() {
      return this.$store.getters.SCHARFFPOINT_ORDER_ORIGIN;
    },
    amountMax() {
      return this.productValue > 1000;
    },
    minWight() {
      return 1;
    },
    maxWeight() {
      return this.packageSize.maxWeight;
    },
  }
};
</script>

<style>
[data-tooltip],
.tooltip {
  position: relative;
  cursor: pointer;
}

/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
  position: absolute;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}

/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Base styles for the tooltip's directional arrow */
.tooltip:before,
[data-tooltip]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltip]:after {
  z-index: 1000;
  padding: 8px;
  width: 160px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  font-size: 14px;
  line-height: 1.2;
}

/* Directions */

/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after,
.tooltip-top:before,
.tooltip-top:after {
  bottom: 100%;
  left: 50%;
}

[data-tooltip]:before,
.tooltip:before,
.tooltip-top:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: #000;
  border-top-color: hsla(0, 0%, 20%, 0.9);
}

/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip:after,
.tooltip-top:after {
  margin-left: -80px;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after {
  -webkit-transform: translateY(-12px);
  -moz-transform: translateY(-12px);
  transform: translateY(-12px);
}

/* Left */
.tooltip-left:before,
.tooltip-left:after {
  right: 100%;
  bottom: 50%;
  left: auto;
}

.tooltip-left:before {
  margin-left: 0;
  margin-right: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-left-color: #000;
  border-left-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-left:hover:before,
.tooltip-left:hover:after,
.tooltip-left:focus:before,
.tooltip-left:focus:after {
  -webkit-transform: translateX(-12px);
  -moz-transform: translateX(-12px);
  transform: translateX(-12px);
}

/* Bottom */
.tooltip-bottom:before,
.tooltip-bottom:after {
  top: 100%;
  bottom: auto;
  left: 50%;
}

.tooltip-bottom:before {
  margin-top: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-bottom-color: #000;
  border-bottom-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-bottom:hover:before,
.tooltip-bottom:hover:after,
.tooltip-bottom:focus:before,
.tooltip-bottom:focus:after {
  -webkit-transform: translateY(12px);
  -moz-transform: translateY(12px);
  transform: translateY(12px);
}

/* Right */
.tooltip-right:before,
.tooltip-right:after {
  bottom: 50%;
  left: 100%;
}

.tooltip-right:before {
  margin-bottom: 0;
  margin-left: -12px;
  border-top-color: transparent;
  border-right-color: #000;
  border-right-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-right:hover:before,
.tooltip-right:hover:after,
.tooltip-right:focus:before,
.tooltip-right:focus:after {
  -webkit-transform: translateX(12px);
  -moz-transform: translateX(12px);
  transform: translateX(12px);
}

/* Move directional arrows down a bit for left/right tooltips */
.tooltip-left:before,
.tooltip-right:before {
  top: 3px;
}

/* Vertically center tooltip content for left/right tooltips */
.tooltip-left:after,
.tooltip-right:after {
  margin-left: 0;
  margin-bottom: -16px;
}
</style>
